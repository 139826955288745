import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllUserApi,
  updateUserStatus,
  getTotalCount,
  deleteUser,
  deleteAddress,
  getPendingProfile,
  updateUserProfileStatus,
  addTowing,
  getTowing,
  getAddress,
  deleteTowingCompany,
  editTowing,
  getAllCurrency,
  getUser,
  getDriver,
  getVehicleDoc,
  addAddress,
  addInspectionAddress,
  deleteInspectionAddress,
  getInspectionAddress,
  getInspection,
  getLicence,
  getInsurance,
  getOwnership,
  getPermit,
  addDateTime,
  getTimeSlots,
  getVehicleDocument,
  updateDocumentStatus,
  getDailyChecklistHistory,
  getChecklistByDate
} from "../../controller/user";
import { clearUserData } from "../../utilities/helper";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  loading: false,
  error: null,
  userVehicle: null,
  vehicleId: null,
};

export const GetAllUser = createAsyncThunk(
  "web/getAllUserApi",
  async (data, { rejectWithValue }) => {
    try {
      console.log("kkk");
      const response = await getAllUserApi(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UserStatus = createAsyncThunk(
  "web/updateUserStatus",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data, "myyyydattaaaa");
      const response = await updateUserStatus(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetTotalCount = createAsyncThunk(
  "web/getTotalCount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getTotalCount(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetPendingProfile = createAsyncThunk(
  "web/getPendingProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getPendingProfile(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UserProfileStatus = createAsyncThunk(
  "web/updateUserProfileStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUserProfileStatus(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteUser = createAsyncThunk("web/deleteUser", async (id, { rejectWithValue }) => {
  console.log(id, "userrrrrrrrrr");
  try {
    const response = await deleteUser(id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const AddTowingasync = createAsyncThunk("web/addTowing", async (data, { rejectWithValue }) => {
  try {
    const response = await addTowing(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCurrencyasync = createAsyncThunk("web/getAllCurrency", async (data, { rejectWithValue }) => {
  try {
    const response = await getAllCurrency(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const GetTowing = createAsyncThunk("web/getTowing", async (data, { rejectWithValue }) => {
  try {
    const response = await getTowing(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
}
);

export const DeleteTowingCompany = createAsyncThunk("web/deleteTowingCompany", async (id, { rejectWithValue }) => {
  try {
    const response = await deleteTowingCompany(id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const EditTowingasync = createAsyncThunk("web/editTowing", async (data, { rejectWithValue }) => {
  try {
    const response = await editTowing(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const getUserAsync = createAsyncThunk("web/getUserApi", async (data, { rejectWithValue }) => {
  try {
    const response = await getUser(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDriverAsync = createAsyncThunk("web/getDriverApi", async (data, { rejectWithValue }) => {
  try {
    const response = await getDriver(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getVehicleDocuments = createAsyncThunk("web/getVehicleDocumentRequest", async (data, { rejectWithValue }) => {
  try {
    const response = await getVehicleDoc(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addAddressAsync = createAsyncThunk("web/addAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await addAddress(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const GetAddress = createAsyncThunk("web/getAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await getAddress(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
}
);

export const DeleteAddress = createAsyncThunk("web/deleteAddress", async (id, { rejectWithValue }) => {
  try {
    const response = await deleteAddress(id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addInspectionAddressAsync = createAsyncThunk("web/addInspectionAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await addInspectionAddress(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const DeleteInspectionAddress = createAsyncThunk("web/deleteInspectionAddress", async (id, { rejectWithValue }) => {
  try {
    const response = await deleteInspectionAddress(id);
    return response;
  } catch (err) {
    return rejectWithValue(err)
  }
});

export const GetInspectionAddress = createAsyncThunk("web/getInspectionAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await getInspectionAddress(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getInsuranceAsync = createAsyncThunk("web/getInsuranceApi", async (data, { rejectWithValue }) => {
  try {
    const response = await getInsurance(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getLicenseAsync = createAsyncThunk("web/getLicenceApi", async (data, { rejectWithValue }) => {
  try {
    const response = await getLicence(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getInspectionAsync = createAsyncThunk("web/getInspectionApi", async (data, { rejectWithValue }) => {
  try {
    const response = await getInspection(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getOwnershipAsync = createAsyncThunk("web/getOwnershipApi", async (data, { rejectWithValue }) => {
  try {
    const response = await getOwnership(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPermitAsync = createAsyncThunk("web/getPermitApi", async (data, { rejectWithValue }) => {
  try {
    const response = await getPermit(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addDateTimeAsync = createAsyncThunk("web/addDateTime", async (data, { rejectWithValue }) => {
  try {
    const response = await addDateTime(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getTimeSlotsAsync = createAsyncThunk("web/getTimeSlots", async (data, { rejectWithValue }) => {
  try {
    const response = await getTimeSlots(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getVehicleHistoryAsync = createAsyncThunk("web/getVehicleHistory", async (data, { rejectWithValue }) => {
  try {
    const response = await getVehicleDocument(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
})


export const DocumentStatusAsync = createAsyncThunk("web/updateDocumentStatus", async (data, { rejectWithValue }) => {
  try {
    const response = await updateDocumentStatus(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
})


export const getChecklistHistoryAsync = createAsyncThunk("web/getDailyChecklistHistory", async (data, { rejectWithValue }) => {
  try {
    const response = await getDailyChecklistHistory(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
})

export const getChecklistByDateAsync = createAsyncThunk("web/getChecklistByDate", async (data, {rejectWithValue}) => {
  try {
    const response = await getChecklistByDate(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
})














export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    logout: (state) => {
      clearUserData();
      state.user = null;
      state.loading = false;
      state.vehicleId = null;
    },

    setUserVehicle: (state, action) => {
      state.userDetail = action.payload; // Set user detail in Redux state
    },
    setVehicleId: (state, action) => {
      state.vehicleId = action.payload; // Update vehicleId in state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPendingProfile.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors
      })
      .addCase(GetPendingProfile.fulfilled, (state, { payload }) => {
        state.user = payload; // Store the profile data
        state.loading = false;
      })
      .addCase(GetPendingProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload; // Store the error message
        toast.error("Failed to fetch profile data"); // Show error message
      });
  },
});

export const { logout, setUserVehicle, setVehicleId } = webSlice.actions;
export const selectUserProfile = (state) => state.web.user;
export const selectLoading = (state) => state.web.loading;
export const selectError = (state) => state.web.error;
export const selectUserVehicle = (state) => state.web.userDetail;
export const selectVehicleId = (state) => state.web.vehicleId;

export default webSlice.reducer;
