import React, { useEffect, useState } from "react";
import { Box, Typography, Modal, CircularProgress, IconButton, Table, TableBody, TableRow, TableContainer } from "@mui/material";
import MDBox from "components/MDBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { DeleteAddress, GetAddress, GetInspectionAddress, DeleteInspectionAddress } from "../../redux/user/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { TableHeadStyle } from "layouts/styles.js";
import Tooltip from "@mui/material/Tooltip";
import deleteImg from "../../../src/assets/images/Delete.svg";
import DeleteModal from "./deleteModal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '45%',
    maxWidth: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};

const ViewAddress = (props) => {
    const dispatch = useDispatch();
    const { open, setOpen, inpsectionAddress } = props;
    const [loading, setLoading] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [userId, setUserId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);

    const handleClose = () => setOpen(false);

    const getAllAddress = () => {
        setLoading(true);
        const action = inpsectionAddress ? dispatch(GetInspectionAddress()) : dispatch(GetAddress());
        action.then(unwrapResult)
            .then((response) => {
                setAddressList(response.data.payload);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    const handleDelete = async () => {
        inpsectionAddress ? await dispatch(DeleteInspectionAddress(userId)) : await dispatch(DeleteAddress(userId));
        await getAllAddress();
    }

    useEffect(() => {
        getAllAddress();
    }, [open]);

    return (
        <>
            <DeleteModal open={openDelete} setOpen={setOpenDelete} deleteUser={handleDelete} headerText={'Address'} />
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px"
                        }}
                        onClick={handleClose}
                    >
                        <CancelIcon />
                    </IconButton>
                    <Box
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            m: 2,
                            borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                            paddingBottom: "16px",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: "24px",
                                fontWeight: "500",
                                color: "#000000",
                            }}
                        >
                            View Address
                        </Typography>
                    </Box>

                    <Box sx={{ height: "500px", overflowY: "scroll" }}>
                        <MDBox pt={3}>
                            <TableContainer sx={{ boxShadow: "none", paddingBottom: "24px" }}>
                                <Table>
                                    <MDBox component="thead">
                                        <TableRow>
                                            <MDBox
                                                component="th"
                                                width="auto"
                                                py={1.5}
                                                px={3}
                                                sx={{
                                                    borderBottom: "1px solid #f0f2f5",
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <MDBox sx={{ ...TableHeadStyle }}>Address</MDBox>
                                            </MDBox>
                                            <MDBox
                                                component="th"
                                                width="auto"
                                                py={1.5}
                                                px={3}
                                                sx={{
                                                    borderBottom: "1px solid #f0f2f5",
                                                }}
                                            >
                                                <MDBox sx={{ ...TableHeadStyle, textAlign: "end" }}>Action</MDBox>
                                            </MDBox>
                                        </TableRow>
                                    </MDBox>
                                </Table>
                            </TableContainer>
                        </MDBox>

                        {loading ? (
                            <>
                                <TableBody>
                                    <TableRow>
                                        <MDBox
                                            component="td"
                                            py={1.5}
                                            px={3}
                                            sx={{
                                                textAlign: "center",
                                            }}
                                            colspan="4"
                                        >
                                            <CircularProgress
                                                size={30}
                                                sx={{
                                                    color: "grey",
                                                    marginTop: "12px",
                                                }}
                                            />
                                        </MDBox>
                                    </TableRow>
                                </TableBody>
                            </>
                        ) : (
                            <TableBody>
                                {addressList && addressList.length > 0 ? (
                                    addressList && addressList.map((value) => (
                                        <TableRow key={value.id}>
                                            <MDBox
                                                component="td"
                                                width="100%"
                                                py={1.5}
                                                px={3}
                                                sx={{
                                                    borderBottom: "1px solid #f0f2f5",
                                                }}
                                            >
                                                <MDBox
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "6px",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#344767",
                                                            fontSize: "0.875rem",
                                                            fontWeight: "600",
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {value.address || "N/A"}
                                                    </Typography>
                                                </MDBox>

                                            </MDBox>
                                            <MDBox
                                                component="td"
                                                py={1.5}
                                                px={3}
                                                sx={{
                                                    borderBottom: "1px solid #f0f2f5",
                                                }}
                                            >
                                                <Tooltip
                                                    title="Delete User"
                                                    placement="top"
                                                >
                                                    <img
                                                        src={deleteImg}
                                                        alt="deleteimg"
                                                        style={{
                                                            width: "24px",
                                                            height: "24px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            setOpenDelete(true);
                                                            setUserId(value?.id);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </MDBox>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <MDBox
                                            component="td"
                                            py={1.5}
                                            px={3}
                                            sx={{
                                                borderBottom: "1px solid #f0f2f5",
                                                textAlign: "center",
                                            }}
                                            colspan={5}
                                        >
                                            <Typography>No data found</Typography>
                                        </MDBox>

                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    </Box>
                </Box>

            </Modal>
        </>
    );

};

export default ViewAddress;
