import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  CircularProgress,
  Button,
} from "@mui/material";
import moment from "moment";
import MDBox from "components/MDBox";
import { TableHeadStyle } from "layouts/styles.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VehicleMenu from "../Menu";
import { useNavigate } from "react-router-dom";
import UncheckIcon from "../../../assets/images/uncheck.svg";
import checkIcon from "../../../assets/images/check.svg";

const DriverCompany = ({ CompanyData, loading, id }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (param) => {
    const query = param?.toLowerCase().replace(/\s+/g, "-");
    navigate(`/history/${id}/${query}`);
    setAnchorEl(null);
  };

  console.log(CompanyData, "CompanyData");

  if (!CompanyData || CompanyData.length === 0) {
    return (
      <Box
        sx={{
          background: "#f5f5f5",
          padding: "24px",
          borderRadius: "8px",
          margin: "0 auto",
          height: "calc(52vh - 100px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
        >
          No Driver Data Available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <MDBox pt={3}>
        <TableContainer sx={{ boxShadow: "none", paddingBottom: "24px" }}>
          <Table>
            <MDBox component="thead">
              <TableRow>
                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                    Owner Name
                  </MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                    Company Name
                  </MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                    Joined Date
                  </MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                    Phone Number
                  </MDBox>
                </MDBox>
                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                    Work Status
                  </MDBox>
                </MDBox>
              </TableRow>
            </MDBox>

            {loading ? (
              <>
                <TableBody>
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        textAlign: "center",
                      }}
                      colspan="4"
                    >
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "grey",
                          marginTop: "12px",
                        }}
                      />
                    </MDBox>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                {CompanyData && CompanyData.length > 0 ? (
                  CompanyData.map((value) => (
                    <TableRow key={value.id}>
                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.company_owner_details?.full_name || "N/A"}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.company_owner_details?.company_name || "N/A"}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {moment(value.joined_date).format("DD/MM/YYYY")}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {`${value.company_owner_details.country_code} ${value.company_owner_details.phone_no}`}
                        </Typography>
                      </MDBox>
                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                    
                        }}
                      >
                        <img
                          src={value?.exit_date ? UncheckIcon : checkIcon}
                          alt={value?.exit_date ? "false" : "true"}
                          width={18}
                          height={18}
                          style={{
                            opacity: "0.7",
                            marginLeft: "20px",
                          }}
                        />
                      </MDBox>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        borderBottom: "1px solid #f0f2f5",
                        textAlign: "center",
                      }}
                      colspan={5}
                    >
                      <Typography>No data found</Typography>
                    </MDBox>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MDBox>

      {/* History Menu */}
      <VehicleMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        menuText={[
          "Service History",
          "Repair History",
          "Renewal History",
          "Daily Checklist History",
          "Marketplace History",
        ]}
      />
    </>
  );
};

export default DriverCompany;
