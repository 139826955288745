import React, { useState } from "react";
import { Box, Typography, Modal, IconButton, Grid, Paper, Tab, Tabs } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import userIcon from "../../../src/assets/images/userIcon.svg";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    maxWidth: "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    border: "none !important",
};

const headingStyle = {
    color: "#344767",
    fontSize: "0.875rem",
    fontWeight: "600",
    textTransform: "capitalize",
    textAlign: "left",
};

const dataStyle = {
    color: " rgba(99, 115, 129, 1)",
    fontSize: "0.875rem",
    fontWeight: "500",
    textTransform: "capitalize",
    textAlign: "left",
};

const detailHeadingStyle = {
    color: "#000000",
    fontSize: "16px",
    fontWeight: 700,
    textAlign: "left",
    marginBottom: "4px",
};

const subheadingStyle = {
    color: "#555555",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
};

const cardStyle = {
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    marginBottom: "20px",
};

const RenewalDetails = (props) => {
    const { open, setOpen, title, data } = props;
    const [value, setValue] = useState(0);

    const handleClose = () => {
        setOpen(false);
        setValue(0);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getGenderLabel = (gender) => {
        switch (gender) {
            case 1:
                return "Male";
            case 2:
                return "Female";
            case 3:
                return "Other";
            default:
                return "Not Specified"; // Optional: Handle undefined gender values
        }
    };

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* Close Button */}
                <IconButton
                    sx={{
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                    }}
                    onClick={handleClose}
                >
                    <CancelIcon />
                </IconButton>

                {/* Modal Title */}
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                        paddingBottom: "16px",
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontSize: "24px",
                            fontWeight: "500",
                            color: "#000000",
                        }}
                    >
                        {title}
                    </Typography>
                </Box>

                {/* Image and Basic Details */}
                <Box sx={{ margin: "12px 0" }}>
                    <Grid container spacing={2} alignItems="center">
                        {/* Profile Image */}
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            md={2}
                            display="flex"
                            justifyContent="center"
                        >
                            <img
                                src={
                                    data?.profile_image ? data?.profile_image : userIcon
                                }
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "50%",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={9} md={10}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {/* User Name */}
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography sx={headingStyle}>Name :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={dataStyle}>
                                            {data?.user_name}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {/* Gender */}
                                {data?.role_id !== 2 && (
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography sx={headingStyle}>Gender :</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography sx={dataStyle}>
                                                {data ? getGenderLabel(data.gender) : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}

                                {/* Mobile Number */}
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography sx={headingStyle}>Mobile no. :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={dataStyle}>
                                            {data?.country_code}
                                            {data?.phone_no}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        sx={{
                            background:
                                "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                            padding: "0.4rem",
                            borderRadius: "0.5rem 0.5rem 0 0",
                            "& .MuiTab-root": {
                                textTransform: "none",
                                fontWeight: "bold",
                                fontSize: "16px",
                                color: "#ffffff !important",
                            },
                            "& .MuiTabs-indicator": {
                                background: "rgba(255, 255, 255, 0.15)",
                                boxShadow: "none",
                            },
                        }}
                    >
                        <Tab label="Personal Info" disableRipple />
                        <Tab label="Vehicles Detail" disableRipple />
                    </Tabs>

                    <Box
                        sx={{
                            background: "#f5f5f5",
                            padding: "24px",
                            borderRadius: "8px",
                            margin: "0 auto",
                            height: "calc(52vh - 100px)",
                            overflowY: "auto",
                        }}
                    >
                        {data && data ? (
                            <Paper key={data.id} sx={cardStyle}>
                                <Grid container spacing={2}>
                                    {value === 0 && (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Full Name:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.user_name || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Email:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.email || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Mobile Number:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.phone_no || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Address:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.address || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Date Of Birth:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.date_of_birth || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Occupation:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.occupation || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </>)}

                                    {value === 1 && (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Engine Number:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_engine_number || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Chassis Number:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_chassis_number || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Vehicle Color:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_color || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Vehicle Make:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_make || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Vehicle Type:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_type || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Vehicle Model:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_model || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Vehicle Category:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_category || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Vehicle Year:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_year || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={detailHeadingStyle}>Plate Number:</Typography>
                                                <Typography sx={subheadingStyle}>
                                                    {data.vehicle_plate_number || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Paper>
                        ) : (
                            <Box
                                sx={{
                                    background: "#f5f5f5",
                                    padding: "24px",
                                    borderRadius: "8px",
                                    margin: "0 auto",
                                    height: "calc(52vh - 100px)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
                                >
                                    No Insurance Data Available
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default RenewalDetails;