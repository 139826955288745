// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
  min-height: auto !important;
  font-size: 16px;
}

.Toastify__toast-body {
  padding: 4px !important;
}

.mask-box {
  border-radius: 20px;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 1px 1px 2px rgb(165 165 165 / 22%) inset;
}

.mask-box .mask {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background: #2b9179;
  position: absolute;
  box-shadow: 1px 0 2px #05a1ab00;
  transition: all 0.5s ease;
}

.mask-box .MuiButton-root {
  border-radius: 20px;
  width: 100px;
  height: 40px;
  font-weight: bold;
  transition: all 0.2s 0.1s ease;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 1rem;
  font-weight: 400;
}

.mask-box .MuiButton-root:hover {
  opacity: 0.8;
}

/* DatePickerStyles.css */
.custom-datepicker {
  border: 1px solid #34476730;
  border-radius: 5px;
  padding: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-datepicker .react-datepicker__header {
  background-color: #007bff;
  color: white;
}

.custom-datepicker .react-datepicker__day--selected {
  background-color: #0056b3;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/public/custom.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,qCAAqC;EACrC,oDAAoD;AACtD;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,8BAA8B;EAC9B,sBAAsB;EACtB,qCAAqC;EACrC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA,yBAAyB;AACzB;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".Toastify__toast {\n  min-height: auto !important;\n  font-size: 16px;\n}\n\n.Toastify__toast-body {\n  padding: 4px !important;\n}\n\n.mask-box {\n  border-radius: 20px;\n  position: relative;\n  background: rgba(255, 255, 255, 0.15);\n  box-shadow: 1px 1px 2px rgb(165 165 165 / 22%) inset;\n}\n\n.mask-box .mask {\n  width: 100px;\n  height: 40px;\n  border-radius: 20px;\n  background: #2b9179;\n  position: absolute;\n  box-shadow: 1px 0 2px #05a1ab00;\n  transition: all 0.5s ease;\n}\n\n.mask-box .MuiButton-root {\n  border-radius: 20px;\n  width: 100px;\n  height: 40px;\n  font-weight: bold;\n  transition: all 0.2s 0.1s ease;\n  color: #fff !important;\n  text-transform: capitalize !important;\n  font-size: 1rem;\n  font-weight: 400;\n}\n\n.mask-box .MuiButton-root:hover {\n  opacity: 0.8;\n}\n\n/* DatePickerStyles.css */\n.custom-datepicker {\n  border: 1px solid #34476730;\n  border-radius: 5px;\n  padding: 10px;\n  background-color: #f8f9fa;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.custom-datepicker .react-datepicker__header {\n  background-color: #007bff;\n  color: white;\n}\n\n.custom-datepicker .react-datepicker__day--selected {\n  background-color: #0056b3;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
