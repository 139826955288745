import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import Icon from "@mui/material/Icon";
import ForgotPassWord from "layouts/authentication/forgotPassword";
import Verify from "layouts/authentication/verify";
import ResetPassword from "layouts/authentication/resetPassword";
import Cars from "layouts/cars";
import Company from "layouts/company";
import Drivers from "layouts/Driver";
import driverImg from "../src/assets/images/driver.svg";
import companyImg from "../src/assets/images/building.svg";
import carImg from "../src/assets/images/car.svg";
import UserProfile from "layouts/userprofile";
import towingImg from "../src/assets/images/towing.svg";
import inspection from '../src/assets/images/inspection.svg';
import license from '../src/assets/images/license.svg';
import insurance from '../src/assets/images/car-insurance.svg';
import carOwnership from '../src/assets/images/ownership.svg';
import clearance from '../src/assets/images/clearance.svg';
import Towing from "layouts/towing";
import VehicleInsurance from "layouts/insurance";
import VehicleLicense from "layouts/License";
import VehicleInpection from "layouts/Inspection";
import Ownership from "layouts/ownership";
import Permits from "layouts/permits";
import UserDetail from "components/commonComponent/userDetail";
import History from "components/commonComponent/history";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    auth: true,
    component: <Dashboard />,
  },
  {
    type: "title",
    key: "user-management-title",
    title: "User Management", // This will be displayed as a heading
  },
  {
    type: "divider", // This adds a divider under "User Management"
    key: "user-management-divider",
  },
  {
    type: "collapse",
    name: "Company",
    key: "user",
    icon: (
      <img
        src={companyImg}
        alt="Driver"
        style={{
          width: "24px",
          height: "24px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/user",
    auth: true,
    component: <Company />,
  },

  {
    type: "hidden",
    name: "Detail",
    key: "detail",
    route: "/:type/:id",
    auth: true,
    component: <UserDetail />,
  },

  {
    type: "hidden",
    name: "History",
    key: "history",
    route: "/history/:id/:history",
    auth: true,
    component: <History />,
  },

  {
    type: "collapse",
    name: "Vehicle Owner",
    key: "vehicle-owner",
    icon: (
      <img
        src={carImg}
        alt="Driver"
        style={{
          width: "24px",
          height: "24px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ), // Use image for Driver,
    route: "/vehicle-owner",
    auth: true,
    component: <Cars />,
  },

  {
    type: "collapse",
    name: "Driver",
    key: "driver",
    icon: (
      <img
        src={driverImg}
        alt="Driver"
        style={{
          width: "24px",
          height: "24px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/driver",
    auth: true,
    component: <Drivers />,
  },
  {
    type: "divider", // This adds a divider under "User Management"
    key: "user-management-divider-1",
  },
  {
    type: "collapse",
    name: "Users Profile",
    key: "user-profile",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/user-profile",
    auth: true,
    component: <UserProfile />,
  },
  {
    type: "collapse",
    name: "Towing",
    key: "towing",
    icon: (
      <img
        src={towingImg}
        alt="Driver"
        style={{
          width: "28px",
          height: "28px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/towing",
    auth: true,
    component: <Towing />,
  },
  {
    type: "title",
    key: "Inpections-title",
    title: "Inspections", // This will be displayed as a heading
  },
  {
    type: "divider", // This adds a divider under "User Management"
    key: "user-management-divider",
  },
  {
    type: "collapse",
    name: "Vehicle Insurance",
    key: "vehicle-insurance",
    icon: (
      <img
        src={insurance}
        alt=""
        style={{
          width: "28px",
          height: "28px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/vehicle-insurance",
    auth: true,
    component: <VehicleInsurance />,
  },
  {
    type: "collapse",
    name: "Vehicle License",
    key: "vehicle-license",
    icon: (
      <img
        src={license}
        alt=""
        style={{
          width: "28px",
          height: "28px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/vehicle-license",
    auth: true,
    component: <VehicleLicense />,
  },
  {
    type: "collapse",
    name: "Vehicle Inspection",
    key: "vehicle-inspection",
    icon: (
      <img
        src={inspection}
        alt=""
        style={{
          width: "28px",
          height: "28px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/vehicle-inspection",
    auth: true,
    component: <VehicleInpection />,
  },
  {
    type: "collapse",
    name: "Ownership",
    key: "ownership",
    icon: (
      <img
        src={carOwnership}
        alt=""
        style={{
          width: "28px",
          height: "28px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/ownership",
    auth: true,
    component: <Ownership />,
  },
  {
    type: "collapse",
    name: "Permits",
    key: "permits",
    icon: (
      <img
        src={clearance}
        alt=""
        style={{
          width: "28px",
          height: "28px",
          filter:
            " invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(300%) contrast(102%)",
        }}
      />
    ),
    route: "/hackney-permits",
    auth: true,
    component: <Permits />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    name: "forgot password",
    key: "forgot password",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/forgot",
    component: <ForgotPassWord />,
  },
  {
    name: "verify",
    key: "verify",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/verify",
    component: <Verify />,
  },
  {
    name: "reset password",
    key: "reset password",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/new-password",
    component: <ResetPassword />,
  },
];

export default routes;
