import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import checkIcon from "../../assets/images/check.svg";
import UncheckIcon from "../../assets/images/uncheck.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 830,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  border: "none !important",
};

const ChecklistModal = (props) => {
  const { open, setOpen, data } = props;

  const handleClose = () => {
    setOpen(false);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
          onClick={handleClose}
        >
          <CancelIcon />
        </IconButton>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            m: 2,
            borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
            paddingBottom: "16px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Checklist Detail
          </Typography>
        </Box>

        <Box>
          {/* 🔹 External Condition */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              borderRadius: "12px",
              mb: 1,
              boxShadow: "none",
              "&:before": { display: "none" },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#666" }} />}
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
                margin: 0,
                fontSize: 28,
              }}
            >
              <Typography
                fontWeight="500"
                sx={{ color: "rgba(1, 33, 20, 1)", fontSize: "1.1rem" }}
              >
                External Condition
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ bgcolor: "#fff", borderRadius: "12px", p: 2 }}
            >
              {[
                { name: "Door", status: "checked" },
                { name: "Internal Taxi License", status: "failed" },
              ].map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={0.8}
                >
                  <Typography
                    sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)" }}
                  >
                    {item.name}
                  </Typography>
                  <img
                    src={item.status === "checked" ? checkIcon : UncheckIcon}
                    alt={item.status}
                    width={22}
                    height={22}
                    style={{ opacity: "0.7" }}
                  />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>

          {/* 🔹 Interior & Equipment */}
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={{
              borderRadius: "12px",
              boxShadow: "none",
              "&:before": { display: "none" },
              //   boxShadow:'0px 8px 16px 0px rgba(0, 0, 0, 0.08) 0px 0px 4px 0px rgba(0, 0, 0, 0.04) !important'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#666" }} />}
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08) !important",
                fontSize: 28,
                margin: 0,
              }}
            >
              <Typography
                fontWeight="500"
                sx={{
                  color: "rgba(1, 33, 20, 1)",
                  fontSize: "1.1rem",
                }}
              >
                Interior & Equipment
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ bgcolor: "#fff", borderRadius: "12px", p: 2 }}
            >
              {[
                "Seatbelt & Locks",
                "Seats",
                "First Aid Kit",
                "Fire Extinguisher",
                "Interior Lights",
                "Horn",
                "Warning Lights",
              ].map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={0.8}
                >
                  <Typography
                    sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)" }}
                  >
                    {item}
                  </Typography>
                  <img
                    src={index % 2 === 0 ? checkIcon : UncheckIcon}
                    alt={index % 2 === 0 ? "checked" : "failed"}
                    width={22}
                    height={22}
                    style={{ opacity: "0.7" }}
                  />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChecklistModal;
