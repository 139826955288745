// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
    min-height: auto !important;
    font-size: 16px;
}
.Toastify__toast-body {
    padding: 4px !important;
} `, "",{"version":3,"sources":["webpack://./src/public/custom.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".Toastify__toast {\n    min-height: auto !important;\n    font-size: 16px;\n}\n.Toastify__toast-body {\n    padding: 4px !important;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
