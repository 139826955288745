import React, { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Modal,
  Skeleton,
} from "@mui/material";
import DocumentModal from "../../Modal/showDocumentModal";

const RenewalData = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpenModal = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };
  console.log(data, "data");
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };
  return (
    <>
      <Box
        sx={{
          height: "calc(86vh - 250px)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Grid container spacing={2}>
          {Array.isArray(data) && data.length > 0 ? (
            data?.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                xxl={2.4}
              >
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    sx={{ height: 140, objectFit: "fill", cursor: "pointer" }}
                    image={item.insurance_image_front}
                    title="Document Image"
                    onClick={() => handleOpenModal(item.insurance_image_front)}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        textAlign: "center",
                        fontSize: "0.85rem",
                      }}
                    >
                      Expired Date: {item.expired_date}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>No data found</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Document Modal */}
      {/* <DocumentModal open={openModal} setOpen={handleCloseModal} imageSrc={selectedImage} title={'Document View'} /> */}
      {selectedImage && (
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ outline: "none" }}>
            <img
              src={selectedImage}
              alt="Document Preview"
              style={{
                maxWidth: "90vw",
                maxHeight: "90vh",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default RenewalData;
