import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  CircularProgress,
  Button,
} from "@mui/material";
import moment from "moment";
import MDBox from "components/MDBox";
import { TableHeadStyle } from "layouts/styles.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VehicleMenu from "../Menu";
import { useNavigate } from "react-router-dom";

import UncheckIcon from "../../../assets/images/uncheck.svg";
import checkIcon from "../../../assets/images/check.svg";

const DriverVehicle = ({ VehicleData, loading, id }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (param) => {
    const query = param?.toLowerCase().replace(/\s+/g, "-");
    navigate(`/history/${id}/${query}`);
    setAnchorEl(null);
  };

  // Function to get permission status
  const getPermissionStatus = (permissions, permissionName) => {
    const permission = permissions?.find(
      (p) => p.permission_details?.name === permissionName
    );

    return permission
      ? { add: permission.add_status || 0, view: permission.view_status || 0 }
      : { add: 0, view: 0 };
  };

  if (!VehicleData || VehicleData.length === 0) {
    return (
      <Box
        sx={{
          background: "#f5f5f5",
          padding: "24px",
          borderRadius: "8px",
          margin: "0 auto",
          height: "calc(52vh - 100px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
        >
          No Vehicle Details Available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <MDBox pt={3}>
        <TableContainer sx={{ boxShadow: "none", paddingBottom: "24px" }}>
          <Table>
            <MDBox component="thead">
              <TableRow>
                {[
                  "Vehicle Name",
                  "Owner Name",
                  "Assigned Date",
                  "Vehicle Number",
                  "Checklist Permission",
                  "Repair Permission",
                  "Service Permission",

                  "View History",
                ].map((header) => (
                  <MDBox
                    key={header}
                    component="th"
                    width="auto"
                    py={1.5}
                    px={3}
                    sx={{ borderBottom: "1px solid #f0f2f5" }}
                  >
                    <Box
                      sx={{
                        minWidth: "140px",
                      }}
                    >
                      <Typography sx={{ ...TableHeadStyle }}>
                        {header}
                      </Typography>
                    </Box>
                  </MDBox>
                ))}
              </TableRow>
            </MDBox>

            {loading ? (
              <>
                <TableBody>
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        textAlign: "center",
                      }}
                      colspan="4"
                    >
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "grey",
                          marginTop: "12px",
                        }}
                      />
                    </MDBox>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                {VehicleData && VehicleData.length > 0 ? (
                  VehicleData.map((value) => {
                    const assignedDate =
                      value.main_driver_id === id
                        ? value.main_driver_vehicle_assigned_date
                        : value.reserve_driver_id === id
                          ? value.reserve_driver_vehicle_assigned_date
                          : "N/A";

                    // Get permission statuses
                    const checklist = getPermissionStatus(
                      value?.vehicle_details?.vehicle_permission_details,
                      "Checklist"
                    );
                    const repair = getPermissionStatus(
                      value?.vehicle_details?.vehicle_permission_details,
                      "Repair"
                    );
                    const service = getPermissionStatus(
                      value?.vehicle_details?.vehicle_permission_details,
                      "Service"
                    );

                    return (
                      <TableRow key={value.id}>
                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            {value.vehicle_details?.model || "N/A"}
                          </Typography>
                        </MDBox>

                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            {value?.vehicle_details?.user_details
                              ?.company_name || "N/A"}
                          </Typography>
                        </MDBox>

                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            {moment(assignedDate).format("DD/MM/YYYY")}
                          </Typography>
                        </MDBox>

                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            {value?.vehicle_details?.vehicle_number || "N/A"}
                          </Typography>
                        </MDBox>

                        {[checklist, repair, service].map((perm, index) => (
                          <MDBox
                            key={index}
                            component="td"
                            py={1.5}
                            px={3}
                            sx={{ borderBottom: "1px solid #f0f2f5" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {["add", "view"].map((type) => (
                                <Box
                                  key={type}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {type.charAt(0).toUpperCase() +
                                      type.slice(1)}{" "}
                                    :
                                  </Typography>
                                  <img
                                    src={perm[type] ? checkIcon : UncheckIcon}
                                    alt={perm[type] ? "Checked" : "Unchecked"}
                                    width={18}
                                    height={18}
                                    style={{
                                      opacity: "0.7",
                                      marginRight: "5px",
                                    }}
                                  />
                                </Box>
                              ))}
                            </Box>
                          </MDBox>
                        ))}

                        {/* Veiw History */}
                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                            textAlign: "center",
                          }}
                        >
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            sx={{
                              color: "#000",
                            }}
                          >
                            <MoreVertIcon sx={{ font: 10, width: "40px" }} />
                          </Button>
                        </MDBox>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        borderBottom: "1px solid #f0f2f5",
                        textAlign: "center",
                      }}
                      colspan={5}
                    >
                      <Typography>No data found</Typography>
                    </MDBox>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MDBox>

      {/* History Menu */}
      <VehicleMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        menuText={[
          "Service History",
          "Repair History",
          "Renewal History",
          "Daily Checklist History",
          "Marketplace History",
        ]}
      />
    </>
  );
};

export default DriverVehicle;
