import React, { useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DriverVehicle from "./driverInfo/driverVehicle";
import DriverCompany from "./driverInfo/driverCompany";
import VehicleRegister from "./vehicleRegister";
import DriverAssign from "./driverAssign";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { selectUserVehicle } from "../../redux/user/userSlice";
import { useSelector } from "react-redux";

const UserDetail = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { userTitle, detailId } = props;
    const userVehicle = useSelector(selectUserVehicle);
    const [value, setValue] = useState(0);
    // const UserData = userTitle === "Driver Detail" ? userVehicle?.driverDetails : userVehicle?.userDetails;
    const UserData = userVehicle?.driverDetails?.role_id === 3 ? userVehicle?.driverDetails : userVehicle?.userDetails;
    const vehicleData = userVehicle?.userVehicles;
    const driverData = userVehicle?.driversRegistered;
    const driverVehicleData = userVehicle?.driverVehicles;
    const driverCompanyData = userVehicle?.driverCompaniesData;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (detailId && UserData) {
            navigate(`/${userTitle.toLowerCase().replace(/\s+/g, "-")}/${detailId}`);
        }
    }, [UserData, detailId]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {parseInt(id) === userVehicle.userDetails?.id || userVehicle.driverDetails?.id ? (
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Box sx={{
                                background: "#fff"
                            }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    sx={{
                                        background:
                                            "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                                        padding: "0.4rem",
                                        borderRadius: "0.5rem 0.5rem 0 0",
                                        "& .MuiTab-root": {
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#ffffff !important",
                                        },
                                        "& .MuiTabs-indicator": {
                                            background: "rgba(255, 255, 255, 0.15)",
                                            boxShadow: "none",
                                        },
                                    }}
                                >
                                    <Tab label="Vehicles Detail" disableRipple />
                                    <Tab
                                        label={UserData?.role_id === 3 ? "Company Detail" : "Driver Detail"}
                                        disableRipple
                                    />
                                </Tabs>

                                {UserData?.role_id === 3 ? (
                                    <>
                                        {value === 0 && <DriverVehicle VehicleData={driverVehicleData} id={UserData?.id} />}
                                        {value === 1 && <DriverCompany CompanyData={driverCompanyData} id={UserData?.id} />}
                                    </>
                                ) : (
                                    <>
                                        {value === 0 && <VehicleRegister vehicleData={vehicleData} id={UserData?.id} />}
                                        {value === 1 && <DriverAssign driverData={driverData} id={UserData?.id} />}
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </MDBox>
            ) : (
                <>
                    <Typography
                        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
                    >
                        No Details Available
                    </Typography>
                </>
            )}
        </DashboardLayout>
    );
};

export default UserDetail;