import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Grid, IconButton, Pagination, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import userIcon from "../../../src/assets/images/userIcon.svg";
import deleteImg from "../../../src/assets/images/Delete.svg";
import activateImge from "../../../src/assets/images/activate.svg";
import deactivateImage from "../../../src/assets/images/deactivate.svg"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { DeleteUser, GetAllUser, UserStatus } from '../../redux/user/userSlice';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import UserDetailModal from 'components/Modal/userDetailModal';
import DeleteModal from 'components/Modal/deleteModal';

const tableHeadStyle = {
    color: "#7b809a",
    fontSize: "0.75rem",
    fontWeight: "700",
    textTransform: "uppercase",
    textAlign: "left"
}

const Company = () => {
    const dispatch = useDispatch();
    const [userList, setUserList] = useState([]);
    const [userDetail, setUserDetail] = useState(null);
    const [viewOpen, setViewOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();

    const getAllUser = () => {
        setLoading(true);
        let params = {
            roleId: '2',
            page: currentPage,   // Pass the current page
            limit: 10,           // Specify how many items per page
        }
        dispatch(GetAllUser(params))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj?.data, "objectAplication");
                setUserList(obj?.data?.payload?.data)
                setTotalPage(obj?.data?.payload?.total_pages)

            })
            .catch((obj) => {
                console.log("err0r");
            })
            .finally(() => setLoading(false));
    }

    const UserStatusUpdate = (id, status) => {
        let params = {
            is_active: status,
            id: id
        }

        console.log(params, "paramssss")
        dispatch(UserStatus(params))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj?.data, "objectAplication");
                getAllUser();
            })
            .catch((obj) => {
                console.log("err0r");
            });
    }

    const handleDelete = async () => {
        await dispatch(DeleteUser(userId));
        await getAllUser();
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);  // Update current page and fetch the users for that page
    };

    useEffect(() => {
        getAllUser()
    }, [currentPage])

    return (
        <>
            <UserDetailModal
                open={viewOpen}
                setOpen={setViewOpen}
                userTitle="Company Detail"
                userDetail={userDetail}
            />
            <DeleteModal
                open={open}
                setOpen={setOpen}
                deleteUser={handleDelete}
            />
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    borderRadius="lg"
                                    sx={{
                                        color: "#ffffff",
                                        background: "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                                    }}
                                >
                                    <MDTypography variant="h6" color="white">
                                        Company Table
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                    <TableContainer sx={{ boxShadow: "none", paddingBottom: "24px" }}>
                                        <Table>
                                            <MDBox component="thead">
                                                <TableRow>
                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...tableHeadStyle }}>
                                                            Owner Name
                                                        </MDBox>
                                                    </MDBox>
                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...tableHeadStyle }} >
                                                            Status
                                                        </MDBox>
                                                    </MDBox>
                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...tableHeadStyle }}>
                                                            Action
                                                        </MDBox>
                                                    </MDBox>
                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...tableHeadStyle }}>
                                                            View Details
                                                        </MDBox>
                                                    </MDBox>
                                                </TableRow>
                                            </MDBox>
                                            {
                                                loading ?
                                                    (
                                                        <>
                                                            <TableBody>
                                                                <TableRow >
                                                                    <MDBox
                                                                        component="td"
                                                                        py={1.5}
                                                                        px={3}
                                                                        sx={{
                                                                            textAlign: "center"
                                                                        }}
                                                                        colspan="4"
                                                                    >
                                                                        <CircularProgress size={30}
                                                                            sx={{
                                                                                color: "grey",
                                                                                marginTop: "12px"
                                                                            }}
                                                                        />
                                                                    </MDBox>
                                                                </TableRow>
                                                            </TableBody>
                                                        </>
                                                    ) :
                                                    (
                                                        <TableBody>
                                                            {userList && userList.length > 0 ? (
                                                                userList?.map(user => (
                                                                    <TableRow key={user.id}>
                                                                        <MDBox
                                                                            component="td"
                                                                            py={1.5}
                                                                            px={3}
                                                                            sx={{
                                                                                borderBottom: "1px solid #f0f2f5",
                                                                            }}
                                                                        >
                                                                            <MDBox sx={{ ...tableHeadStyle, display: "flex", alignItems: "center", gap: "6px" }}>
                                                                                <img src={user?.profile_image ? user?.profile_image : userIcon} alt='carimg' style={{ width: "28px", height: "28px", borderRadius: "50%" }} />
                                                                                <Typography sx={{
                                                                                    color: "#344767",
                                                                                    fontSize: "0.875rem",
                                                                                    fontWeight: "600",
                                                                                    textTransform: "capitalize"
                                                                                }}
                                                                                >
                                                                                    {user.full_name || 'N/A'}
                                                                                </Typography>
                                                                            </MDBox>
                                                                        </MDBox>
                                                                        <MDBox
                                                                            component="td"
                                                                            py={1.5}
                                                                            px={3}
                                                                            sx={{
                                                                                borderBottom: "1px solid #f0f2f5",
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                sx={{
                                                                                    background: user?.is_active === 1 ? "linear-gradient(195deg, #66BB6A, #43A047)" : "linear-gradient(195deg, #42424a, #191919)",
                                                                                    border: "none",
                                                                                    borderRadius: "0.375rem",
                                                                                    color: "#ffffff !important",
                                                                                    padding: "0.55em 0.9em",
                                                                                    fontWeight: "700",
                                                                                    fontSize: "0.5625rem",
                                                                                    minHeight: "1.8rem",
                                                                                    width: "85px"
                                                                                }}>
                                                                                {user?.is_active === 1 ? "Activate" : "Deactivate"}
                                                                            </Button>
                                                                        </MDBox>
                                                                        <MDBox
                                                                            component="td"
                                                                            py={1.5}
                                                                            px={3}
                                                                            sx={{
                                                                                borderBottom: "1px solid #f0f2f5",
                                                                            }}
                                                                        >
                                                                            <Box>
                                                                                <img
                                                                                    src={user?.is_active === 1 ? deactivateImage : activateImge}
                                                                                    alt="activateImge"
                                                                                    style={{ width: "24px", height: "24px", cursor: "pointer", marginRight: "6px" }}
                                                                                    onClick={() => UserStatusUpdate(user?.id, user?.is_active === 1 ? "0" : "1")}
                                                                                />
                                                                                <img
                                                                                    src={deleteImg}
                                                                                    alt="deleteimg"
                                                                                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                                                                                    onClick={() => {
                                                                                        setOpen(true);
                                                                                        setUserId(user?.id)
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </MDBox>
                                                                        <MDBox
                                                                            component="td"
                                                                            py={1.5}
                                                                            px={3}
                                                                            sx={{
                                                                                borderBottom: "1px solid #f0f2f5",
                                                                            }}
                                                                        >
                                                                            <RemoveRedEyeIcon
                                                                                sx={{
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                onClick={() => {
                                                                                    setUserDetail(user);
                                                                                    setViewOpen(true);
                                                                                }}
                                                                            />
                                                                        </MDBox>
                                                                    </TableRow>
                                                                )))
                                                                : (
                                                                    <TableRow>
                                                                        <MDBox
                                                                            component="td"
                                                                            py={1.5}
                                                                            px={3}
                                                                            sx={{
                                                                                borderBottom: "1px solid #f0f2f5",
                                                                                textAlign: "center"
                                                                            }}
                                                                            colspan={4}
                                                                        >
                                                                            <Typography>No data found</Typography>
                                                                        </MDBox>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    )
                                            }
                                        </Table>
                                    </TableContainer>
                                </MDBox>
                            </Card>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, alignItems: "end" }}>
                                <Pagination
                                    count={totalPage} // Total pages based on user data
                                    page={currentPage} // Current page
                                    onChange={handlePageChange} // Handle page change
                                    color="grey" // You can change the color if needed
                                    // variant="outlined"
                                    shape="rounded"
                                    sx={{
                                        "& .css-1eaa915-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {

                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardLayout>
        </>
    )
}

export default Company