import { ApiClient } from "../utilities/api";
import ApiPath from "../constants/apiPath";

export function getAllUserApi(data) {
  const url = ApiPath.UserApiPath.GET_ALL_USER;
  return ApiClient.get(url, data);
}
export function updateUserStatus(data) {
  const url = ApiPath.UserApiPath.UPDATE_USER_STATUS + `${data.id}`;
  return ApiClient.patch(url, data);
}
export function getTotalCount(data) {
  const url = ApiPath.UserApiPath.GET_TOTAL_COUNT;
  return ApiClient.get(url, data);
}
export function deleteUser(data) {
  console.log(data, "dataaaaaaid");
  const url = ApiPath.UserApiPath.DELETE_USER + data;
  return ApiClient.delete(url, data);
}
export function getPendingProfile(data) {
  const url = ApiPath.UserApiPath.PENDING_REQUEST;
  return ApiClient.get(url, data);
}
export function updateUserProfileStatus(data) {
  const url = `${ApiPath.UserApiPath.UPDATE_PROFILE_STATUS}/${data.id}`;
  return ApiClient.patch(url, data);
}
export function addTowing(data) {
  const url = ApiPath.UserApiPath.ADD_TOWING;
  return ApiClient.post(url, data);
}

export function getAllCurrency(data) {
  const url = ApiPath.UserApiPath.GET_ALL_CURRENCY;
  return ApiClient.get(url, data);
}

export function getTowing(data) {
  const url = ApiPath.UserApiPath.GET_TOWING;
  return ApiClient.get(url, data);
}

export function deleteTowingCompany(data) {
  const url = ApiPath.UserApiPath.DELETE_TOW + data;
  return ApiClient.delete(url, data);
}

export function editTowing(data) {
  const url = `${ApiPath.UserApiPath.EDIT_TOWING}/${data.id}`;

  return ApiClient.patch(url, data);
}

export function getUser(data) {
  const url = ApiPath.UserApiPath.GET_USER + data;
  return ApiClient.get(url, data);
}

export function getDriver(data) {
  const url = ApiPath.UserApiPath.GET_DRIVER + data;
  return ApiClient.get(url, data);
}

export function getVehicleDoc(data) {
  const url = ApiPath.UserApiPath.GET_VEHICLE_DOCUMENTS;
  return ApiClient.get(url, data);
}

export function addAddress(data) {
  const url = ApiPath.UserApiPath.ADD_ADDRESS;
  return ApiClient.post(url, data);
}

export function getAddress(data) {
  const url = ApiPath.UserApiPath.GET_ADDRESS;
  return ApiClient.get(url, data);
}

export function deleteAddress(data) {
  const url = ApiPath.UserApiPath.DELETE_ADDRESS + data;
  return ApiClient.delete(url, data);
}

export function addInspectionAddress(data) {
  const url = ApiPath.UserApiPath.ADD_INSPECTION_ADDRESS;
  return ApiClient.post(url, data);
}

export function deleteInspectionAddress(data) {
  const url = ApiPath.UserApiPath.DELETE_INSPECTION_ADDRESS + data;
  return ApiClient.delete(url, data);
}

export function getInspectionAddress(data) {
  const url = ApiPath.UserApiPath.GET_INSPECTION_ADDRESS;
  return ApiClient.get(url, data);
}

export function getInsurance(data) {
  const url = ApiPath.UserApiPath.GET_INSURANCE + data;
  return ApiClient.get(url, data);
}

export function getLicence(data) {
  const url = ApiPath.UserApiPath.GET_LICENSE + data;
  return ApiClient.get(url, data);
}

export function getInspection(data) {
  const url = ApiPath.UserApiPath.GET_INSPECTION + data;
  return ApiClient.get(url, data);
}

export function getOwnership(data) {
  const url = ApiPath.UserApiPath.GET_OWNERSHIP + data;
  return ApiClient.get(url, data);
}

export function getPermit(data) {
  const url = ApiPath.UserApiPath.GET_PERMITS + data;
  return ApiClient.get(url, data);
}

export function addDateTime(data) {
  const url = ApiPath.UserApiPath.ADD_DATE_TIME;
  return ApiClient.post(url, data);
}

export function getTimeSlots(data) {
  const url = ApiPath.UserApiPath.GET_TIME_SLOTS + data;
  return ApiClient.get(url, data);
}

export function getVehicleDocument(data) {
  const documentType = data.documentType;
  const url = ApiPath.UserApiPath.GET_VEHICLE_DOCUMENT + data.id;
  return ApiClient.get(url, {documentType});
}

export function updateDocumentStatus(data) {
  const url = `${ApiPath.UserApiPath.UPDATE_DOCUMENT_STATUS}?documentType=${data.documentType}&requestId=${data.id}`;
  return ApiClient.patch(url, data);
}

export function getDailyChecklistHistory (data) {
  const url = ApiPath.UserApiPath.GET_DAILY_CHECKLIST_HISTORY;
  return ApiClient.get(url, data);
}

export function getChecklistByDate (data) {
  const url = ApiPath.UserApiPath.GET_CHECKLIST_BY_DATE;
  return ApiClient.get(url, data);
}