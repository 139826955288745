const ApiPath = {
  AuthApiPath: {
    LOGIN: "/api/v1/users/admin/login",
    FORGOT_PASSWORD: "/api/v1/users/forgot/password",
    RESET_PASSWORD: "/api/v1/users/reset/password",
  },
  UserApiPath: {
    GET_ALL_USER: "api/v1/users/all",
    UPDATE_USER_STATUS: "/api/v1/users/activate/deactivate/",
    GET_TOTAL_COUNT: "/api/v1/users/admin/home",
    DELETE_USER: "/api/v1/users/",
    PENDING_REQUEST: "/api/v1/users/pending/requests",
    UPDATE_PROFILE_STATUS: "/api/v1/users/approve/reject/profile/request",
    ADD_TOWING: "/api/v1/towing_companies",
    GET_TOWING: "/api/v1/towing_companies",
    DELETE_TOW: "/api/v1/towing_companies/",
    EDIT_TOWING:"/api/v1/towing_companies",
    GET_ALL_CURRENCY:"/api/v1/users/countries",
    GET_USER:"/api/v1/users/details/",
    GET_DRIVER:"/api/v1/users/driver/details/",
    GET_VEHICLE_DOCUMENTS: "/api/v1/users/document/requests",
    ADD_ADDRESS: "/api/v1/document_address",
    GET_ADDRESS: "/api/v1/document_address",
    DELETE_ADDRESS: "/api/v1/document_address/",
    ADD_INSPECTION_ADDRESS: "/api/v1/vehicle_inspection_address",
    DELETE_INSPECTION_ADDRESS: "/api/v1/vehicle_inspection_address/",
    GET_INSPECTION_ADDRESS: "/api/v1/vehicle_inspection_address/admin",
    GET_INSURANCE: "/api/v1/vehicle_documents/insurance/details/",
    GET_INSPECTION: "/api/v1/vehicle_documents/inspection/details/",
    GET_LICENSE: "/api/v1/vehicle_documents/license/details/",
    GET_OWNERSHIP: "/api/v1/vehicle_documents/ownership/proof/details/",
    GET_PERMITS: "/api/v1/vehicle_documents/hackney/permit/details/",
    ADD_DATE_TIME: "/api/v1/vehicle_inspection_address/add/date/time",
    GET_TIME_SLOTS: "/api/v1/vehicle_inspection_address/date/",
    GET_VEHICLE_DOCUMENT: "/api/v1/vehicle_documents/list/",
    UPDATE_DOCUMENT_STATUS: "/api/v1/vehicle_documents/status",
    GET_DAILY_CHECKLIST_HISTORY: "/api/v1/daily_checklist_details/",
    GET_CHECKLIST_BY_DATE: "/api/v1/daily_checklist_details/data/"
  },
};

export default ApiPath;
