import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllUserApi, updateUserStatus, getTotalCount, deleteUser, getPendingProfile, updateUserProfileStatus, addTowing, getTowing, deleteTowingCompany, editTowing, getAllCurrency } from "../../controller/user";
import { clearUserData } from "../../utilities/helper";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  loading: false,
  error: null, // Add error state
};

export const GetAllUser = createAsyncThunk(
  "web/getAllUserApi",
  async (data, { rejectWithValue }) => {
    try {
      console.log("kkk");
      const response = await getAllUserApi(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UserStatus = createAsyncThunk(
  "web/updateUserStatus",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data, "myyyydattaaaa");
      const response = await updateUserStatus(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetTotalCount = createAsyncThunk(
  "web/getTotalCount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getTotalCount(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetPendingProfile = createAsyncThunk(
  "web/getPendingProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getPendingProfile(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UserProfileStatus = createAsyncThunk(
  "web/updateUserProfileStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUserProfileStatus(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteUser = createAsyncThunk("web/deleteUser", async (id, { rejectWithValue }) => {
  console.log(id, "userrrrrrrrrr");
  try {
    const response = await deleteUser(id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const AddTowingasync = createAsyncThunk("web/addTowing", async (data, { rejectWithValue }) => {
  try {
    const response = await addTowing(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCurrencyasync = createAsyncThunk("web/getAllCurrency", async (data, { rejectWithValue }) => {
  try {
    const response = await getAllCurrency(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const GetTowing = createAsyncThunk("web/getTowing", async (data, { rejectWithValue }) => {
  try {
    const response = await getTowing(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
}
);

export const DeleteTowingCompany = createAsyncThunk("web/deleteTowingCompany", async (id, { rejectWithValue }) => {
  try {
    const response = await deleteTowingCompany(id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const EditTowingasync = createAsyncThunk("web/editTowing", async (data, { rejectWithValue }) => {
  try {
    const response = await editTowing(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});












export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    logout: (state) => {
      clearUserData();
      state.user = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPendingProfile.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors
      })
      .addCase(GetPendingProfile.fulfilled, (state, { payload }) => {
        state.user = payload; // Store the profile data
        state.loading = false;
      })
      .addCase(GetPendingProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload; // Store the error message
        toast.error("Failed to fetch profile data"); // Show error message
      });
  },
});

export const { logout } = webSlice.actions;
export const selectUserProfile = (state) => state.web.user;
export const selectLoading = (state) => state.web.loading;
export const selectError = (state) => state.web.error;

export default webSlice.reducer;
