import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Grid, Typography, Pagination } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import userIcon from "../../../src/assets/images/userIcon.svg";
import { GetPendingProfile, selectLoading, selectUserProfile, UserProfileStatus } from '../../redux/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import ProfileConfirmModal from 'components/Modal/profileConfirmModal';
import ProfileUpdateModal from 'components/Modal/profileUpdateModal';
import { Search } from "layouts/styles.js";
import SearchIcon from "@mui/icons-material/Search";
import { SearchIconWrapper } from "layouts/styles.js";
import { StyledInputBase } from "layouts/styles.js";

const UserProfile = () => {
  const userProfile = useSelector(selectUserProfile);
  const profileData = userProfile?.data?.payload?.data;
  const loading = useSelector(selectLoading);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [roleStatus, setRoleStatus] = useState('')
  const [selectedId, setSelectedId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [userUpdate, setUserUpdate] = useState(null)
  const parsedData = JSON.parse(userUpdate);
  const [search, setSearch] = useState("");
  const [subType, setSubType] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();

  console.log(roleStatus, "roleStatus")

  const getGenderLabel = (gender) => {
    switch (gender) {
      case 1:
        return "Male";
      case 2:
        return "Female";
      case 3:
        return "Other";
      default:
        return "Not Specified"; // Optional: Handle undefined gender values
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
    setSelectedStatus(null);
  };

  const handleConfirm = () => {
    updateRequest(selectedId, selectedStatus);
    handleClose();
  };

  const getUserProfile = () => {
    let params = {
      roleId: "3",
      limit: 10, // Specify how many items per page
      search: search,
      status: subType,
      page: currentPage,
    };
    dispatch(GetPendingProfile(params))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj?.data, "objectAplication");
        setUserList(obj?.data?.payload?.data);
        setTotalPage(obj?.data?.payload?.total_pages);
      })
      .catch((obj) => {
        console.log("err0r");
      })
  };

  const updateRequest = () => {
    let params = {
      id: selectedId,
      status: selectedStatus
    }
    dispatch(UserProfileStatus(params))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj?.data, "objectAplication");
        getUserProfile();
      })
      .catch((obj) => {
        console.log("err0r");
      });
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value); 
  };

  useEffect(() => {
    getUserProfile();
  }, [currentPage, subType, search]);


  return (
    <>
      <ProfileUpdateModal
        open={openUpdate}
        setOpen={setOpenUpdate}
        userDetail={parsedData}
        roleStatus={roleStatus}
      />
      <ProfileConfirmModal
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        status={selectedStatus === 1 ? "Accept" : "Reject"}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  borderRadius="lg"
                  sx={{
                    color: "#ffffff",
                    background: "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Users Pending Request
                  </MDTypography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearch}
                      />
                    </Search>
                    <Box className="mask-box">
                      <Box
                        className="mask"
                        style={{
                          transform: `translateX(${subType === "0" ? "0" : subType === "1" ? "120px" : "250px"})`,
                          width: "120px"
                        }}
                      />
                      <Button
                        disableRipple
                        variant="text"
                        sx={{
                          whiteSpace: "nowrap",
                          width: "120px !important"
                        }}
                        onClick={() => setSubType("0")}
                      >
                        View Request
                      </Button>
                      <Button
                        disableRipple
                        variant="text"
                        sx={{
                          width: "120px !important"
                        }}
                        onClick={() => setSubType("1")}
                      >
                        Accept
                      </Button>
                      <Button
                        disableRipple
                        variant="text"
                        sx={{
                          width: "130px !important"
                        }}
                        onClick={() => setSubType("2")}
                      >
                        Reject
                      </Button>
                    </Box>
                  </Box>
                </MDBox>
                <Box sx={{ padding: "24px" }}>
                  {
                    loading ?
                      (
                        <Box sx={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "center",
                        }}>
                          <CircularProgress size={30} sx={{ color: "grey", }} />
                        </Box>
                      ) :
                      (profileData?.length > 0 ?
                        (
                          profileData.map((val, idx) => (
                            <Box key={idx} sx={{
                              border: "1px solid rgba(204, 206, 205, 1)",
                              borderRadius: 2,
                              p: 1.5,
                              display: "flex",
                              alignItems: "start",
                              justifyContent: "space-between",
                              mb: 2 // Add margin between the items if needed
                            }}>
                              <Box sx={{
                                display: "flex",
                                alignItems: "start",
                                gap: "8px"
                              }}>
                                <img src={val?.user_details?.profile_image || userIcon} alt='user-icon' style={{ width: "35px", height: "35px", borderRadius: "50%" }} />
                                <Box>
                                  <Typography sx={{
                                    color: "#344767",
                                    fontSize: "0.875rem",
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                    marginRight: "8px"
                                  }}>
                                    User Role : {
                                      val?.user_details?.role_id === 2
                                        ? "Company"
                                        : val?.user_details?.role_id === 1
                                          ? "Car-Owner"
                                          : "Driver"
                                    }
                                  </Typography>
                                  <Typography sx={{
                                    color: "#344767",
                                    fontSize: "0.875rem",
                                    fontWeight: "400",
                                    textTransform: "capitalize"
                                  }}>
                                    {
                                      val?.user_details?.role_id === 2
                                        ? `Company Name: ${val?.user_details?.company_name || ""}`
                                        : ` Name: ${val?.user_details?.full_name || ""}`
                                    }
                                  </Typography>
                                  <Typography sx={{
                                    color: "#344767",
                                    fontSize: "0.875rem",
                                    fontWeight: "400",
                                    textTransform: "capitalize"
                                  }}>
                                    Mobile no.: {val?.user_details?.country_code}{val?.user_details?.phone_no}
                                  </Typography>

                                  <Typography sx={{
                                    color: "#344767",
                                    fontSize: "0.875rem",
                                    fontWeight: "400",
                                    textTransform: "capitalize"
                                  }}>
                                    {
                                      val?.user_details?.role_id === 2
                                        ? `Full Name: ${val?.user_details?.full_name || ""}`
                                        : `Gender: ${getGenderLabel(val?.user_details?.gender)}`
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Button
                                  onClick={() => { setOpenUpdate(true); setUserUpdate(val?.data); setRoleStatus(val?.user_details?.role_id); }}
                                  sx={{
                                    background: "rgba(250, 190, 25, 0.1) !important",
                                    padding: "5px 10px",
                                    color: "rgba(250, 190, 25, 1)!important",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    marginRight: "10px !important"
                                  }}>
                                  View Request
                                </Button>
                                <Button
                                  onClick={() => { setOpen(true); setSelectedId(val?.id); setSelectedStatus(1) }}
                                  sx={{
                                    background: "rgba(4, 167, 102, 0.1) !important",
                                    padding: "5px 10px",
                                    color: "rgba(4, 167, 102, 1) !important",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                    marginRight: "10px !important"
                                  }}>
                                  Accept
                                </Button>
                                <Button
                                  onClick={() => { setOpen(true); setSelectedId(val?.id); setSelectedStatus(2) }}
                                  sx={{
                                    background: "rgba(249, 52, 52, 0.1) !important",
                                    padding: "5px 10px",
                                    color: "rgba(249, 52, 52, 1)!important",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    textTransform: "capitalize"
                                  }}>
                                  Reject
                                </Button>
                              </Box>
                            </Box>
                          ))
                        )
                        :
                        (
                          <Box sx={{
                            borderRadius: 2,
                            p: 1.5,
                            textAlign: "center",
                            mb: 2
                          }}>
                            <Typography>No data found</Typography>
                          </Box>
                        )
                      )
                  }
                </Box>
              </Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  alignItems: "end",
                }}
              >
                <Pagination
                  count={totalPage} // Total pages based on user data
                  page={currentPage} // Current page
                  onChange={handlePageChange} // Handle page change
                  color="grey" // You can change the color if needed
                  shape="rounded"
                  sx={{
                    "& .css-1eaa915-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                      {},
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  )
}

export default UserProfile