import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, IconButton } from '@mui/material';
import userIcon from "../../../src/assets/images/userIcon.svg";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 585,
    maxWidth: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    border: "none !important"
};
const headingStyle = {
    color: "#344767",
    fontSize: "0.875rem",
    fontWeight: "600",
    textTransform: "capitalize",
    textAlign: "left"
}
const dataStyle = {
    color: " rgba(99, 115, 129, 1)",
    fontSize: "0.875rem",
    fontWeight: "500",
    textTransform: "capitalize",
    textAlign: "left"
}

const UserDetailModal = (props) => {
    const { open, setOpen, userTitle, userDetail } = props
    const handleClose = () => setOpen(false);

    const getGenderLabel = (gender) => {
        switch (gender) {
            case 1:
                return "Male";
            case 2:
                return "Female";
            case 3:
                return "Other";
            default:
                return "Not Specified"; // Optional: Handle undefined gender values
        }
    };

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    sx={{
                        position: "absolute",
                        top: "16px",
                        right: "16px"
                    }}
                    onClick={handleClose}
                >
                    <CancelIcon />
                </IconButton>
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                        paddingBottom: "16px"
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontSize: "24px",
                            fontWeight: "500",
                            color: "#000000"
                        }}>
                        {userTitle}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: "center", margin: "24px 0" }}>
                    <img src={userDetail?.profile_image ? userDetail?.profile_image : userIcon}
                        style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%"
                        }} />
                </Box>
                {
                    userTitle === "Company Detail" && <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <Typography sx={headingStyle}>
                                Company Name :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography sx={dataStyle}>
                                {userDetail?.company_name}
                            </Typography>
                        </Grid>
                    </Grid>
                }

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <Typography sx={headingStyle}>
                            Name :
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography sx={dataStyle}>
                            {userDetail?.full_name}
                        </Typography>
                    </Grid>
                </Grid>
                {
                    userDetail?.role_id !== 2 &&
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <Typography sx={headingStyle}>
                                Gender :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography sx={dataStyle}>
                                {userDetail ? getGenderLabel(userDetail.gender) : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                }

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <Typography sx={headingStyle}>
                            Mobile no. :
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography sx={dataStyle}>
                            {userDetail?.country_code}{userDetail?.phone_no}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default UserDetailModal