import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Modal, Box, IconButton, Typography } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxWidth: "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    border: "none !important",
};

const DocumentModal = (props) => {
    const { open, setOpen, title, imageSrc } = props;

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* Close Button */}
                <IconButton
                    sx={{
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                    }}
                    onClick={handleClose}
                >
                    <CancelIcon />
                </IconButton>

                {/* Modal Title */}
                <Box
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                        paddingBottom: "16px",
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontSize: "24px",
                            fontWeight: "500",
                            color: "#000000",
                        }}
                    >
                        {title}
                    </Typography>
                </Box>

                <Box sx={{
                    width:"100%",
                    height:"50vh"
                }}>
                    <img
                        src={imageSrc}
                        alt="Document"
                        style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "8px",
                            imageRendering:"pixelated"
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    )
};

export default DocumentModal;