import React from "react";

const ServiceHistory = (props) => {
    return (
        <>
            Service History
        </>
    );
};

export default ServiceHistory;