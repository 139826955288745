import React, { useState, useEffect } from "react";
import { Chip, Table, TableBody, TableContainer, TableRow, Typography, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import { TableHeadStyle } from "layouts/styles.js";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ChecklistModal from "components/Modal/checklistModal";
import { useDispatch, useSelector } from "react-redux";
import { getChecklistByDateAsync, getChecklistHistoryAsync, selectVehicleId } from "../../../redux/user/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const ChecklistHistory = () => {
  const dispatch = useDispatch();
  const vehicleId = useSelector(selectVehicleId);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checklistHistoryData, setChecklistHistoryData] = useState(null);
  const [checklistDetail, setChecklistDetail] = useState(null);

  const getCheckList = () => {
    setLoading(true);
    const params = {
      vehicle_id: vehicleId
    };

    dispatch(getChecklistHistoryAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setChecklistHistoryData(obj?.data?.payload.data);
      })
      .catch((obj) => { })
      .finally(() => setLoading(false));
  };

  const handleChecklistDetails = (date) => {
    setOpen(true);
    const params = {
      search_date: date,
      vehicle_id: vehicleId
    };

    dispatch(getChecklistByDateAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setChecklistDetail(obj?.data?.payload?.data);
      })
      .catch((obj) => { })

  };

  useEffect(() => {
    getCheckList();
  }, []);

  return (
    <>
      <ChecklistModal open={open} setOpen={setOpen} data={checklistDetail} />

      <MDBox pt={0}>
        <TableContainer sx={{ boxShadow: "none", paddingBottom: "24px" }}>
          <Table>
            <MDBox component="thead">
              <TableRow>
                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle }}>Date</MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",

                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle }}>Status</MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle }}> Check</MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",

                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle }}>Uncheck</MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle }}>Failed checks</MDBox>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <MDBox sx={{ ...TableHeadStyle, textAlign: "center" }}>
                    View Details
                  </MDBox>
                </MDBox>
              </TableRow>
            </MDBox>

            {loading ? (
              <>
                <TableBody>
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        textAlign: "center",
                      }}
                      colspan="4"
                    >
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "grey",
                          marginTop: "12px",
                        }}
                      />
                    </MDBox>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                {checklistHistoryData && checklistHistoryData.length > 0 ? (
                  checklistHistoryData.map((value) => (
                    <>
                      <TableRow key={value.id}>
                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>{value.created_at.split("T")[0]}</Typography>
                        </MDBox>
                        <MDBox
                          component="td"
                          width="25%"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <MDBox
                            sx={{
                              ...TableHeadStyle,

                            }}
                          >
                            <Chip
                              label={value?.checkListStatus}
                              sx={{
                                backgroundColor: value?.checkListStatus === "incomplete"
                                  ? "rgba(158, 54, 35, 0.12)"
                                  : value?.checkListStatus === "Not conducted"
                                    ? "rgba(249, 52, 52, 0.14)"
                                    : "#DFF6E3",
                                color: value?.checkListStatus === "incomplete"
                                  ? "rgba(158, 54, 35, 1)"
                                  : value?.checkListStatus === "Not conducted"
                                    ? "rgba(249, 52, 52, 1)"
                                    : "rgba(4, 167, 102, 1)",
                                fontWeight: "normal",
                                borderRadius: "16px",
                                fontSize: "0.9rem",
                                textTransform: "capitalize"
                              }}
                              size="medium"
                            />
                          </MDBox>
                        </MDBox>
                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>{value?.check}</Typography>
                        </MDBox>
                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>{value?.uncheck}</Typography>
                        </MDBox>
                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>{value?.failed}</Typography>
                        </MDBox>

                        <MDBox
                          component="td"
                          py={1.5}
                          px={3}
                          sx={{
                            borderBottom: "1px solid #f0f2f5",
                            textAlign: "center",
                          }}
                        >
                          <RemoveRedEyeIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleChecklistDetails(value.created_at.split("T")[0]);
                            }}
                          />
                        </MDBox>
                      </TableRow>
                    </>
                  ))
                ) : (
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        borderBottom: "1px solid #f0f2f5",
                        textAlign: "center",
                      }}
                      colspan={5}
                    >
                      <Typography>No data found</Typography>
                    </MDBox>
                  </TableRow>
                )}
              </TableBody>
            )}

          </Table>
        </TableContainer>
      </MDBox>
    </>
  );
};

export default ChecklistHistory;
