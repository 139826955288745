import React, { useEffect, useState } from "react";
import { Box, Grid, Tabs, Tab, FormControl, Select, MenuItem, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getVehicleHistoryAsync, selectVehicleId } from "../../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import RenewalData from "./renewalData";

const RenewalHistory = () => {
  const dispatch = useDispatch();
  const vehicleId = useSelector(selectVehicleId);
  const [value, setValue] = useState(0);
  const [renewalHistoryData, setRenewalHistoryData] = useState(null);
  const [documentTypeId, setDocumentTypeId] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState(null);
  const tabText = ['Vehicles Insurance', 'Vehicles License', 'Vehicles Inspection', 'Ownership', 'Permits', 'Road Worthiness'];

  const handleChange = (event, newValue) => {
    const documentTypeIds = [1, 2, 5, 3, 4, 6];
    setValue(newValue);
    setDocumentTypeId(documentTypeIds[newValue]);
    getHistoryDocument(documentTypeIds[newValue]);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setFilteredDocuments(renewalHistoryData?.documentsByExpiry[selectedValue] || []);
  };

  const getHistoryDocument = (doctype) => {
    const params = { id: vehicleId, documentType: doctype };
    dispatch(getVehicleHistoryAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setRenewalHistoryData(obj?.data?.payload);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getHistoryDocument(documentTypeId);
  }, []);

  useEffect(() => {
    if (renewalHistoryData) {
      const availableOptions = Object.entries(renewalHistoryData.documentsByExpiry || {}).filter(
        ([, docs]) => docs.length > 0
      );
      if (availableOptions.length > 0) {
        const firstAvailableKey = availableOptions[0][0];
        setSelectedOption(firstAvailableKey);
        setFilteredDocuments(renewalHistoryData.documentsByExpiry[firstAvailableKey]);
      } else {
        setSelectedOption("");
        setFilteredDocuments([]);
      }
    }
  }, [renewalHistoryData, vehicleId]);

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={2}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            orientation="vertical"
            sx={{
              background: "transparent",
              padding: "1rem",
              borderRadius: "0.5rem 0.5rem 0 0",
              "& .MuiTab-root": {
                textTransform: "none",
                fontSize: "14px",
                color: "#344767",
                marginBottom: "10px",
                padding: "12px 16px",
                border: "1px solid rgba(116, 110, 110, 0.32)",
                background: "rgba(255, 255, 255, 0.15)",
                transition: "all 0.3s ease",
                fontWeight: "normal",
              },
              "& .Mui-selected": {
                background:
                  "linear-gradient(90deg, rgba(2,67,41,1) 0%, rgba(0, 0, 0, 1) 100%)",
                color: "#fff !important",
                boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
                border: "1px solid ",
              },
              "& .MuiTabs-indicator": {
                background: "rgba(2,67,41,1)",
                width: "3px",
                marginRight: "-10px",
              },
            }}
          >
            {tabText.map((item, index) => (
              <Tab key={index} label={item} disableRipple />
            ))}
          </Tabs>
        </Box>
      </Grid>

      <Grid item xs={12} sm={8} md={9} lg={9} xl={9} xxl={10}>
        <Box
          sx={{
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap:"wrap",
            gap:3
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              letterSpacing: "1px",
              textAlign: "left",
              display: "inline-block",
            }}
          >
            {tabText[value]}
          </Typography>

          <FormControl sx={{ minWidth: "220px" }}>
            <Select
              value={selectedOption}
              onChange={handleSelectChange}
              displayEmpty
              IconComponent={() => (
                <ExpandMoreIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      color: "#000",
                      backgroundColor: "#fff",
                      height: "30px",
                      width: "30px",
                      position: "absolute",
                      right: "6px",
                    },
                  }}
                />
              )}
              className="customSelect"
              sx={{
                fontFamily: "sans-serif",
                fontStyle: "normal",
                color: selectedOption === "" ? "#FFFFFF20" : "#FFF",
                borderRadius: "0.8rem",
                width: "100%",
                height: "52px",
                fontSize: "14px",
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "6px",
                    border: "1px solid #FFFFFF4D",
                    background: "#100e1f",
                    color: "#ffffff",
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: "5px",
                    "& .MuiMenuItem-root": {
                      color: "#344767",
                      fontSize: "14px",
                      fontWeight: "400",
                      "&.Mui-selected, &:hover": {
                        backgroundColor: "#f0f0f0",
                        color: "#344767",
                      },
                    },
                  },
                },
              }}
            >
              {/* Default disabled option */}
              <MenuItem value="" disabled>
                Select Expiry Status
              </MenuItem>

              {/* Expiry Status Options */}
              {[
                { key: "alreadyExpired", label: "Already Expired" },
                { key: "expiringInOneWeek", label: "Expiring in One Week" },
                { key: "expiringInThreeMonths", label: "Expiring in Three Months" },
                { key: "expiringInSixMonths", label: "Expiring in Six Months" },
                { key: "expiringAfterSixMonths", label: "Expiring After Six Months" },
              ].map(({ key, label }) => (
                <MenuItem
                  key={key}
                  value={key}
                  disabled={!renewalHistoryData?.documentsByExpiry?.[key]?.length}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


        </Box>

        {/* Renewal Data */}
        <Box>
          <RenewalData data={filteredDocuments} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RenewalHistory;
