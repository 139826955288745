import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CircularProgress, Grid, Typography, Pagination, Table, TableRow, TableBody, TableContainer } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { Search } from 'layouts/styles.js';
import SearchIcon from "@mui/icons-material/Search";
import { SearchIconWrapper } from 'layouts/styles.js';
import { StyledInputBase } from "layouts/styles.js";
import { getVehicleDocuments, getOwnershipAsync, DocumentStatusAsync } from '../../redux/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { TableHeadStyle } from "layouts/styles.js";
import AddAddress from 'components/Modal/addAddressModal';
import ViewAddress from 'components/Modal/viewAddressModal';
import RenewalDetails from 'components/Modal/renewalDetailModal';
import userIcon from "../../../src/assets/images/userIcon.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from 'moment';

const Ownership = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [ownershipList, setOwnershipList] = useState([]);
    const [open, setOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);
    const [detailOpen, setDetailOpen] = useState(false);
    const [ownershipDetail, setOnwershipDetail] = useState(null);

    const getAllOwnershipProof = () => {
        setLoading(true);
        const params = {
            documentType: 3,
            page: currentPage,
            limit: 10,
            search: search,
        };
        dispatch(getVehicleDocuments(params))
            .then(unwrapResult)
            .then((response) => {
                setOwnershipList(response?.data?.payload?.data);
                setTotalPage(response?.data?.payload?.total_pages);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const getOwenershipDetail = async (id) => {
        setDetailOpen(true);
        dispatch(getOwnershipAsync(id))
            .then(unwrapResult)
            .then((obj) => {
                setOnwershipDetail(obj?.data?.payload);
            })
            .catch((obj) => { });
    };

    const handleStatus = (id) => {
        let params = {
            id: id,
            documentType: 3,
            request_status: 3,
        };
        dispatch(DocumentStatusAsync(params))
            .then((obj) => { getAllOwnershipProof() })
            .catch((obj) => { console.log("err0r"); });
    };

    useEffect(() => {
        getAllOwnershipProof();
    }, [search, currentPage]);

    return (
        <>
            <AddAddress open={open} setOpen={setOpen} />
            <ViewAddress open={viewOpen} setOpen={setViewOpen} />
            <RenewalDetails open={detailOpen} setOpen={setDetailOpen} title={'Ownership Detail'} data={ownershipDetail} />

            <DashboardLayout>
                <DashboardNavbar />

                <Box sx={{ textAlign: "end" }}>
                    <Button
                        sx={{
                            background: "rgba(4, 167, 102, 0.1) !important",
                            padding: "12px 10px",
                            color: "rgba(4, 167, 102, 1) !important",
                            fontSize: 16,
                            fontWeight: 500,
                            textTransform: "capitalize",
                            marginRight: "10px !important",
                            width: "100%",
                            maxWidth: "122px",
                            whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                            setViewOpen(true);
                        }}
                    >
                        View Address
                    </Button>
                    <Button
                        sx={{
                            background: "rgba(4, 167, 102, 0.1) !important",
                            padding: "12px 10px",
                            color: "rgba(4, 167, 102, 1) !important",
                            fontSize: 16,
                            fontWeight: 500,
                            textTransform: "capitalize",
                            marginRight: "10px !important",
                            width: "100%",
                            maxWidth: "122px",
                        }}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Add Address
                    </Button>
                </Box>

                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    borderRadius="lg"
                                    sx={{
                                        color: "#ffffff",
                                        background: "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                    }}
                                >
                                    <MDTypography variant="h6" color="white">
                                        Annual Proof of Ownership
                                    </MDTypography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search…"
                                                inputProps={{ "aria-label": "search" }}
                                                onChange={handleSearch}
                                            />
                                        </Search>
                                    </Box>
                                </MDBox>

                                <MDBox pt={3}>
                                    <TableContainer sx={{ boxShadow: "none", paddingBottom: "24px" }}>
                                        <Table>
                                            <MDBox component="thead">
                                                <TableRow>
                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                            display: "flex",
                                                            gap: "10px",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...TableHeadStyle }}>Owner Name</MDBox>
                                                    </MDBox>

                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...TableHeadStyle }}>
                                                            {" "}
                                                            Phone No.
                                                        </MDBox>
                                                    </MDBox>

                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...TableHeadStyle }}>
                                                            {" "}
                                                            Vehicle Year
                                                        </MDBox>
                                                    </MDBox>

                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox sx={{ ...TableHeadStyle }}>
                                                            {" "}
                                                            Register Date
                                                        </MDBox>
                                                    </MDBox>

                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox
                                                            sx={{ ...TableHeadStyle, textAlign: "center" }}
                                                        >
                                                            Action
                                                        </MDBox>
                                                    </MDBox>

                                                    <MDBox
                                                        component="th"
                                                        width="auto"
                                                        py={1.5}
                                                        px={3}
                                                        sx={{
                                                            borderBottom: "1px solid #f0f2f5",
                                                        }}
                                                    >
                                                        <MDBox
                                                            sx={{ ...TableHeadStyle, textAlign: "center" }}
                                                        >
                                                            View Details
                                                        </MDBox>
                                                    </MDBox>
                                                </TableRow>
                                            </MDBox>

                                            {loading ? (
                                                <>
                                                    <TableBody>
                                                        <TableRow>
                                                            <MDBox
                                                                component="td"
                                                                py={1.5}
                                                                px={3}
                                                                sx={{
                                                                    textAlign: "center",
                                                                }}
                                                                colspan="4"
                                                            >
                                                                <CircularProgress
                                                                    size={30}
                                                                    sx={{
                                                                        color: "grey",
                                                                        marginTop: "12px",
                                                                    }}
                                                                />
                                                            </MDBox>
                                                        </TableRow>
                                                    </TableBody>
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {ownershipList && ownershipList.length > 0 ? (
                                                        ownershipList.map((value) => (
                                                            <TableRow key={value.id}>
                                                                <MDBox
                                                                    component="td"
                                                                    width="25%"
                                                                    py={1.5}
                                                                    px={3}
                                                                    sx={{
                                                                        borderBottom: "1px solid #f0f2f5",
                                                                    }}
                                                                >
                                                                    <MDBox
                                                                        sx={{
                                                                            ...TableHeadStyle,
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "6px",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                value?.profile_image
                                                                                    ? value?.profile_image
                                                                                    : userIcon
                                                                            }
                                                                            alt="carimg"
                                                                            style={{
                                                                                width: "28px",
                                                                                height: "28px",
                                                                                borderRadius: "50%",
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            sx={{
                                                                                color: "#344767",
                                                                                fontSize: "0.875rem",
                                                                                fontWeight: "600",
                                                                                textTransform: "capitalize",
                                                                            }}
                                                                        >
                                                                            {value.user_name || "N/A"}
                                                                        </Typography>
                                                                    </MDBox>
                                                                </MDBox>

                                                                <MDBox
                                                                    component="td"
                                                                    py={1.5}
                                                                    px={3}
                                                                    sx={{
                                                                        borderBottom: "1px solid #f0f2f5",
                                                                    }}
                                                                >
                                                                    <Typography sx={{ fontSize: "14px" }}>
                                                                        {value.phone_no || "N/A"}
                                                                    </Typography>
                                                                </MDBox>

                                                                <MDBox
                                                                    component="td"
                                                                    py={1.5}
                                                                    px={3}
                                                                    sx={{
                                                                        borderBottom: "1px solid #f0f2f5",
                                                                    }}
                                                                >
                                                                    <Typography sx={{ fontSize: "14px" }}>
                                                                        {value.vehicle_year || "N/A"}
                                                                    </Typography>
                                                                </MDBox>

                                                                <MDBox
                                                                    component="td"
                                                                    py={1.5}
                                                                    px={3}
                                                                    sx={{
                                                                        borderBottom: "1px solid #f0f2f5",
                                                                    }}
                                                                >
                                                                    <Typography sx={{ fontSize: "14px" }}>
                                                                        {moment(value.created_at).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </Typography>
                                                                </MDBox>

                                                                <MDBox
                                                                    component="td"
                                                                    py={1.5}
                                                                    px={3}
                                                                    sx={{
                                                                        borderBottom: "1px solid #f0f2f5",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        onClick={() => handleStatus(value.id)}
                                                                        sx={{
                                                                            background: "rgba(4, 167, 102, 0.1) !important",
                                                                            padding: "5px 10px",
                                                                            color: "rgba(4, 167, 102, 1) !important",
                                                                            fontSize: 14,
                                                                            fontWeight: 500,
                                                                            textTransform: "capitalize",
                                                                            marginRight: "10px !important"
                                                                        }}>
                                                                        Approve
                                                                    </Button>
                                                                </MDBox>

                                                                <MDBox
                                                                    component="td"
                                                                    py={1.5}
                                                                    px={3}
                                                                    sx={{
                                                                        borderBottom: "1px solid #f0f2f5",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    <RemoveRedEyeIcon
                                                                        sx={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            getOwenershipDetail(value?.id);
                                                                        }}
                                                                    />
                                                                </MDBox>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <MDBox
                                                                component="td"
                                                                py={1.5}
                                                                px={3}
                                                                sx={{
                                                                    borderBottom: "1px solid #f0f2f5",
                                                                    textAlign: "center",
                                                                }}
                                                                colspan={5}
                                                            >
                                                                <Typography>No data found</Typography>
                                                            </MDBox>

                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </MDBox>
                            </Card>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mt: 2,
                                    alignItems: "end",
                                }}
                            >
                                <Pagination
                                    count={totalPage}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="grey"
                                    shape="rounded"
                                    sx={{
                                        "& .css-1eaa915-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                                            {},
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardLayout>
        </>
    )

};

export default Ownership