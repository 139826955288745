import React, { useState } from "react";
import {
  Box, Typography, Table, TableBody, TableContainer, TableRow, CircularProgress,
  Button
} from "@mui/material";
import MDBox from "components/MDBox";
import { TableHeadStyle } from "layouts/styles.js";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VehicleMenu from "./Menu";
import { useNavigate } from "react-router-dom";
import { setVehicleId } from "../../redux/user/userSlice";
import { useDispatch } from "react-redux";


const VehicleRegister = ({ vehicleData, loading, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e, id) => {
    dispatch(setVehicleId(id));
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (param) => {
    const query = param?.toLowerCase().replace(/\s+/g, "-");
    navigate(`/history/${id}/${query}`);
    setAnchorEl(null);
  };

  if (!vehicleData || vehicleData.length === 0) {
    return (
      <Box
        sx={{
          background: "#f5f5f5",
          padding: "24px",
          borderRadius: "8px",
          margin: "0 auto",
          height: "calc(52vh - 100px)",
          overflowY: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}>
          No Vehicle Details Available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <MDBox pt={3}>
        <TableContainer sx={{ boxShadow: "none", paddingBottom: "24px" }}>
          <Table>
            <MDBox component="thead">
              <TableRow>
                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Vehicle Name
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Brand Name
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Registration Year
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Vehicle Number
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Engine Number
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Chassis Number
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Vehicle Color
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Engine Capacity
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      Mileage
                    </MDBox>
                  </Box>
                </MDBox>

                <MDBox
                  component="th"
                  width="auto"
                  py={1.5}
                  px={3}
                  sx={{
                    borderBottom: "1px solid #f0f2f5",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",

                  }}>
                    <MDBox sx={{ ...TableHeadStyle, whiteSpace: "nowrap" }}>
                      {" "}
                      View History
                    </MDBox>
                  </Box>
                </MDBox>
              </TableRow>
            </MDBox>

            {loading ? (
              <>
                <TableBody>
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        textAlign: "center",
                      }}
                      colspan="4"
                    >
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "grey",
                          marginTop: "12px",
                        }}
                      />
                    </MDBox>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                {vehicleData && vehicleData.length > 0 ? (
                  vehicleData?.map((value) => (
                    <TableRow key={value.id}>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.model}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.car_brand_details?.name}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.registration_year}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.vehicle_number}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.engine_number}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.chassis_number}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.vehicle_color}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.engine_capacity_details.text}
                        </Typography>
                      </MDBox>

                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {value.millage_details.text}
                        </Typography>
                      </MDBox>

                      {/* Veiw History */}
                      <MDBox
                        component="td"
                        py={1.5}
                        px={3}
                        sx={{
                          borderBottom: "1px solid #f0f2f5",
                          textAlign: "center"
                        }}
                      >
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => handleClick(e, value.id)}
                          sx={{
                            color: "#000"
                          }}
                        >
                          <MoreVertIcon sx={{ font: 10, width: "40px" }} />
                        </Button>
                      </MDBox>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <MDBox
                      component="td"
                      py={1.5}
                      px={3}
                      sx={{
                        borderBottom: "1px solid #f0f2f5",
                        textAlign: "center",
                      }}
                      colspan={5}
                    >
                      <Typography>No data found</Typography>
                    </MDBox>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MDBox>

      {/* History Menu */}
      <VehicleMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        menuText={['Service History', 'Repair History', 'Renewal History', 'Daily Checklist History', 'Marketplace History']}
      />
    </>
  );
};

export default VehicleRegister;
