import React from "react";
import { Menu, MenuItem } from "@mui/material";

const VehicleMenu = (props) => {
    const { anchorEl, open, handleClose, menuText } = props;
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {menuText && menuText.map((menuText) => (
                <>
                    <MenuItem onClick={() => handleClose(menuText)}>{menuText}</MenuItem>
                </>
            ))}
        </Menu>
    )
};

export default VehicleMenu;