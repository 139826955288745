import React, { useEffect, useState } from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { addDateTimeAsync, getTimeSlotsAsync } from "../../redux/user/userSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import AddressSection from "components/commonComponent/addressSection";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 585,
    maxWidth: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};

const AddDateTime = (props) => {
    const { open, setOpen, firstAddress, secondAddress, addressId } = props;
    console.log('addressId ==>',addressId);
    
    const dispatch = useDispatch();
    const [dateTimeArray, setDateTimeArray] = useState([]);
    console.log('dateTimeArray:', dateTimeArray);
    const [firstAddressList, setFirstAddressList] = useState([]);
    const [secondAddressList, setSecondAddressList] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };


    const getAvailableTimeSlots = async (para) => {
        dispatch(getTimeSlotsAsync(addressId))
            .then(unwrapResult)
            .then((response) => {
                if (para === "first") {
                    setFirstAddressList(response?.data?.payload);
                } else {

                    setSecondAddressList(response?.data?.payload);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const handleDateChange = (addressID, date) => {
        let validDate = date;

        if (validDate instanceof Date && !isNaN(validDate.getTime())) {
            const formattedDate = moment(validDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
            const newEntry = { address_id: addressID, available_date_time: formattedDate };
            setDateTimeArray(prevArray => {
                const existingEntryIndex = prevArray.findIndex(entry => entry.address_id === addressID);
                if (existingEntryIndex !== -1) {
                    const updatedArray = [...prevArray];
                    updatedArray[existingEntryIndex] = newEntry;
                    return updatedArray;
                } else {
                    return [...prevArray, newEntry];
                }
            });
        } else {
            console.error("Invalid Date selected", date);
        }
    };

    const addInspectionDateTime = (id) => {
        const params = {
            inspection_request_id: id,
            dateTime: dateTimeArray
        };

        dispatch(addDateTimeAsync(params))
            .then(unwrapResult)
            .then((response) => {
                if (response) {
                    getAvailableTimeSlots();
                    toast.success("Add Date Time successfully!");
                }
            })
            .catch((error) => {
                toast.error(error?.msg);
            });
    };

    useEffect(() => {
        const addresses = [
            { address: firstAddress, label: 'first' },
            { address: secondAddress, label: 'second' }
        ];
        addresses.forEach(({ address, label }) => {
            if (address) {
                getAvailableTimeSlots(label);
            }
        });
    }, [firstAddress, secondAddress]);

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px"
                        }}
                        onClick={handleClose}
                    >
                        <CancelIcon />
                    </IconButton>

                    <Box
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            m: 2,
                            borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                            paddingBottom: "16px",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: "24px",
                                fontWeight: "500",
                                color: "#000000",
                            }}
                        >
                            Add Time Slot
                        </Typography>
                    </Box>

                    <Box>
                        {/* first Address */}
                        {firstAddress && firstAddress ? (
                            <AddressSection
                                address={firstAddress}
                                dateTimeArray={dateTimeArray}
                                handleDateChange={handleDateChange}
                                addInspectionDateTime={addInspectionDateTime}
                                availableTimeSlots={firstAddressList}
                                addressId={addressId}
                            />
                        ) : null}

                        {/* Second Address */}
                        {secondAddress && secondAddress ? (
                            <AddressSection
                                address={secondAddress}
                                dateTimeArray={dateTimeArray}
                                handleDateChange={handleDateChange}
                                addInspectionDateTime={addInspectionDateTime}
                                availableTimeSlots={secondAddressList}
                                addressId={addressId}
                            />
                        ) : null}
                    </Box>
                </Box>
            </Modal>
        </>
    )
};

export default AddDateTime;
