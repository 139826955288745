import React from "react";

const MarketplaceHistory = (props) => {
    return (
        <>
            Market Place History
        </>
    );
};

export default MarketplaceHistory;