import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const headingStyle = {
    color: "#344767",
    fontSize: "1rem",
    fontWeight: "600",
    textTransform: "capitalize",
    textAlign: "left",
};

const AddressSection = (props) => {
    const { address, dateTimeArray, handleDateChange, addInspectionDateTime, availableTimeSlots, addressId } = props;
    const selectedDate = dateTimeArray.find(entry => entry.address_id === address.id)?.available_date_time;
    console.log('selectedDate >>>>>>>',selectedDate);
    return (
        <Box
            sx={{
                m: 2,
                borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                paddingBottom: "16px",
            }}>
            {address ? (
                <Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "space-between"
                    }}>
                        <Typography sx={headingStyle}>
                            {address.address}
                        </Typography>
                        <Box>
                            <DatePicker
                                selected={selectedDate ? new Date(selectedDate) : selectedDate}
                                onChange={(date) => handleDateChange(address.id, date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="yyyy-MM-dd HH:mm"
                                className="custom-datepicker"
                            />
                        </Box>
                        <Button
                            disableRipple
                            variant="text"
                            onClick={() => addInspectionDateTime(addressId)}
                        >
                            Add Time Slots
                        </Button>
                    </Box>

                    <Grid container spacing={2} sx={{ marginTop: "8px" }}>
                        {availableTimeSlots && availableTimeSlots.map((item) => (
                            <Grid item xs={12} sm={4} key={item.id}>
                                <Box sx={{
                                    border: "1px solid #62caa6",
                                    padding: "6px",
                                    borderRadius: "6px"
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "#62caa6"
                                        }}
                                    >
                                        {moment(item.available_date_time).format('DD/MM/YYYY (HH:mm)')}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ) : null}
        </Box>
    );
};

export default AddressSection;
