import { ApiClient } from "../utilities/api";
import ApiPath from "../constants/apiPath";

export function getAllUserApi(data) {
  const url = ApiPath.UserApiPath.GET_ALL_USER;
  return ApiClient.get(url, data);
}
export function updateUserStatus(data) {
  const url = ApiPath.UserApiPath.UPDATE_USER_STATUS + `${data.id}`;
  return ApiClient.patch(url, data);
}
export function getTotalCount(data) {
  const url = ApiPath.UserApiPath.GET_TOTAL_COUNT;
  return ApiClient.get(url, data);
}
export function deleteUser(data) {
  console.log(data, "dataaaaaaid");
  const url = ApiPath.UserApiPath.DELETE_USER + data;
  return ApiClient.delete(url, data);
}
export function getPendingProfile(data) {
  const url = ApiPath.UserApiPath.PENDING_REQUEST;
  return ApiClient.get(url, data);
}
export function updateUserProfileStatus(data) {
  const url = `${ApiPath.UserApiPath.UPDATE_PROFILE_STATUS}/${data.id}`;
  return ApiClient.patch(url, data);
}
export function addTowing(data) {
  const url = ApiPath.UserApiPath.ADD_TOWING;
  return ApiClient.post(url, data);
}

export function getAllCurrency(data) {
  const url = ApiPath.UserApiPath.GET_ALL_CURRENCY;
  return ApiClient.get(url, data);
}

export function getTowing(data) {
  const url = ApiPath.UserApiPath.GET_TOWING;
  return ApiClient.get(url, data);
}

export function deleteTowingCompany(data) {
  const url = ApiPath.UserApiPath.DELETE_TOW + data;
  return ApiClient.delete(url, data);
}

export function editTowing(data) {
  const url = `${ApiPath.UserApiPath.EDIT_TOWING}/${data.id}`;
  return ApiClient.patch(url, data);
}
