import React, { useEffect, useState } from "react";
import { Box, Typography, Modal, FormControl, MenuItem, Select, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import mapboxgl from "mapbox-gl";
import MDBox from "components/MDBox";
import { useForm } from "react-hook-form";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { addAddressAsync, addInspectionAddressAsync } from "../../redux/user/userSlice";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 585,
    maxWidth: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};

const AddAddress = (props) => {

    const { open, setOpen, inpsectionAddress } = props;
    const [loading, setLoading] = useState(false);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [address, setAddress] = useState("");
    const dispatch = useDispatch();
    const currentDate = Date.now();
    const [suggestions, setSuggestions] = useState([]);
    const { handleSubmit, register, setValue, formState: { errors }, reset } = useForm({
        defaultValues: {
            location: "",
        },
    });

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const onSave = () => {
        setLoading(true);
        const params = {
            type: 1,
            address: address,
            lattitude: latitude !== null ? latitude.toString() : "",
            longitude: longitude !== null ? longitude.toString() : "",
        };
        const action = inpsectionAddress ? dispatch(addInspectionAddressAsync(params)) : dispatch(addAddressAsync(params));
            action.then(unwrapResult)
            .then((response) => {
                if (response) {
                    toast.success("Add Address successfully!");
                    handleClose();
                }
            })
            .catch((error) => {
                toast.error(error?.msg);
            })
            .finally(() => setLoading(false));
    };

    const handleLocationChange = async (event) => {
        const location = event.target.value;
        setValue("location", location);
        setAddress(location);
        if (location) {
            const query = encodeURIComponent(location);
            const url = `https://api.mapbox.com/search/searchbox/v1/suggest?q=${query}&limit=10&language=en&session_token=${currentDate.millisecondsSinceEpoch}&access_token=${mapboxgl.accessToken}`;

            const response = await fetch(url);
            const data = await response.json();
            if (data?.suggestions?.length > 0) {
                setSuggestions(data?.suggestions);
            } else {
                setSuggestions([]);
            }
        } else {
            setLatitude(null);
            setLongitude(null);
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = async (feature) => {
        const id = feature.mapbox_id;
        const url = `https://api.mapbox.com/search/searchbox/v1/retrieve/${id}?session_token=${currentDate.millisecondsSinceEpoch}&access_token=${mapboxgl.accessToken}`;
        setSuggestions([]);
        setValue("location", ` ${feature?.full_address || ""} ,${feature?.name}`);

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.features && data.features.length > 0) {
                const featureData = data.features[0];
                const coordinates = featureData.geometry.coordinates;
                setLongitude(coordinates[0]);
                setLatitude(coordinates[1]);
                setValue(
                    "location",
                    feature.full_address || feature.name
                );
                setAddress(feature.full_address || feature.name);
                setSuggestions([]);
            } else {
                console.warn("No features found in the response.");
            }
        } catch (error) {
            console.error("Error retrieving location details:", error);
        }
    };

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px"
                        }}
                        onClick={handleClose}
                    >
                        <CancelIcon />
                    </IconButton>
                    <Box
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            m: 2,
                            borderBottom: "1px solid rgba(122, 122, 122, 0.17)",
                            paddingBottom: "16px",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: "24px",
                                fontWeight: "500",
                                color: "#000000",
                            }}
                        >
                            Add Address
                        </Typography>
                    </Box>

                    <MDBox component="form" role="form" onSubmit={handleSubmit(onSave)}>
                        {/* Location field */}
                        <MDBox mb={2}>
                            <MDInput
                                type="text"
                                label="Location"
                                fullWidth
                                {...register("location")}
                                onChange={handleLocationChange}
                            />
                            {errors.location && (
                                <Typography sx={{ fontSize: 10, color: "red" }}>
                                    {errors.location?.message}
                                </Typography>
                            )}
                        </MDBox>

                        {/* Suggeston field */}
                        {suggestions.length > 0 && (
                            <Box
                                sx={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    zIndex: 1000,
                                    position: "absolute",
                                    backgroundColor: "#fff",
                                    width: "100%",
                                    maxWidth: "565px",
                                }}
                            >
                                {suggestions.map((feature, index) => (
                                    <Box
                                        key={index}
                                        onClick={() => handleSuggestionClick(feature)}
                                        sx={{
                                            padding: "8px",
                                            paddingBottom: "0 !important",
                                            cursor: "pointer",
                                            "&:hover": { backgroundColor: "#f0f0f0" },
                                        }}
                                    >
                                        {/* Displaying the full_address or name of the location */}
                                        <Typography
                                            sx={{
                                                fontSize: "0.86rem !important",
                                            }}
                                        >{`${feature.full_address || ""} , ${feature.name}`}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}

                        {/* Save Address button */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <MDButton
                                type="submit"
                                variant="gradient"
                                disabled={loading}
                                sx={{
                                    color: "#ffffff",
                                    background:
                                        "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%) !important",
                                    marginTop: "24px",
                                    textTransform: "capitalize",
                                    fontSize: 14,
                                    padding: "14px 34px",
                                    width: "100%",
                                    maxWidth: "160px",
                                    "&.Mui-disabled": {
                                        backgroundColor: "grey !important",
                                        color: "#fff",
                                    },
                                }}
                            >
                                {loading ? "Saving..." : "Save"}
                            </MDButton>
                        </Box>
                    </MDBox>
                </Box>
            </Modal>
        </>
    )

};

export default AddAddress;