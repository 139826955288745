import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Grid, Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import RenewalHistory from "./renewalHistory";
import ChecklistHistory from "./checklistHistory";
import MarketplaceHistory from "./marketplaceHistory";
import RepairHistory from "./repairHistory";
import ServiceHistory from "./serviceHistory";
import { useDispatch, useSelector } from "react-redux";
import { selectUserVehicle, getVehicleHistoryAsync } from "../../../redux/user/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const History = () => {
    const location = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const userVehicle = useSelector(selectUserVehicle);
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    const [value, setValue] = useState(0);
    const [renewalHistoryData, setRenewalHistoryData] = useState(null);
    const [documentTypeId, setDocumentTypeId] = useState(null);
    const formattedTitle = lastSegment
        .replace(/-/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());


    const getHistoryDocument = () => {
        const params = {
            id: 7,
            documentType: documentTypeId,
        }
        dispatch(getVehicleHistoryAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setRenewalHistoryData(obj?.data?.payload);
            })
            .catch((obj) => { });
    };

    useEffect(() => {
        const MenuItem = [
            'Service History',
            'Repair History',
            'Renewal History',
            'Daily Checklist History',
            'Marketplace History'
        ];

        const index = MenuItem.indexOf(formattedTitle);
        if (index) {
            setValue(index);
        }

    }, [formattedTitle]);

    return (<>
        <DashboardLayout>
            <DashboardNavbar />
            {parseInt(id) === userVehicle.userDetails.id ?
                (
                    <>
                        <MDBox pt={6} pb={3}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <Card>
                                        <MDBox
                                            mx={2}
                                            mt={-3}
                                            py={3}
                                            px={2}
                                            variant="gradient"
                                            borderRadius="lg"
                                            sx={{
                                                color: "#ffffff",
                                                background:
                                                    "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                flexWrap: "wrap",
                                                gap: "5px",
                                            }}
                                        >
                                            <MDTypography variant="h6" color="white">
                                                {formattedTitle}
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox pt={3}>
                                            {value === 0 && <ServiceHistory />}
                                            {value === 1 && <RepairHistory />}
                                            {value === 2 && <RenewalHistory />}
                                            {value === 3 && <ChecklistHistory />}
                                            {value === 4 && <MarketplaceHistory />}
                                        </MDBox>
                                    </Card>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </>
                ) : (
                    <>
                        <Typography
                            sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
                        >
                            No Details Available
                        </Typography>
                    </>
                )
            }
        </DashboardLayout>
    </>
    );
};

export default History;