import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddTowing from "components/Modal/addTowing";
import {
  DeleteTowingCompany,
  getCurrencyasync,
  GetTowing,
} from "../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import DeleteModal from "components/Modal/deleteModal";
import { Search } from "layouts/styles.js";
import SearchIcon from "@mui/icons-material/Search";
import { SearchIconWrapper } from "layouts/styles.js";
import { StyledInputBase } from "layouts/styles.js";

const Towing = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [towingList, setTowingList] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [editCompany, setEditCompany] = useState(null);
  const [getCurrency, setGetCurrency] = useState(null);
  const [search, setSearch] = useState("");

  const getAllTowings = () => {
    setLoading(true);
    let params = {
      page: currentPage, // Pass the current page
      limit: 10, // Specify how many items per page
      search: search,
    };
    dispatch(GetTowing(params))
      .then(unwrapResult)
      .then((response) => {
        setTowingList(response?.data?.payload?.data);
        setTotalPage(response?.data?.payload?.total_pages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page and fetch the users for that page
  };

  const handleDelete = async () => {
    await dispatch(DeleteTowingCompany(companyId));
    await getAllTowings();
  };

  const getAllCurrencyHandle = () => {
    dispatch(getCurrencyasync())
      .then(unwrapResult)
      .then((response) => {
        setGetCurrency(response?.data?.payload);
      })
      .catch((error) => {
        toast.error(error?.msg);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllTowings();
  }, [currentPage, search]);

  useEffect(() => {
    getAllCurrencyHandle();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <AddTowing
        open={open}
        setOpen={setOpen}
        getAllTowings={getAllTowings}
        editCompany={editCompany}
        setEditCompany={setEditCompany}
        getCurrency={getCurrency}
      />

      <DeleteModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteUser={handleDelete}
      />

      <DashboardLayout>
        <DashboardNavbar />
        <Box sx={{ textAlign: "end" }}>
          <Button
            sx={{
              background: "rgba(4, 167, 102, 0.1) !important",
              padding: "12px 10px",
              color: "rgba(4, 167, 102, 1) !important",
              fontSize: 16,
              fontWeight: 500,
              textTransform: "capitalize",
              marginRight: "10px !important",
              width: "100%",
              maxWidth: "122px",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Add Towing
          </Button>
        </Box>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  borderRadius="lg"
                  sx={{
                    color: "#ffffff",
                    background:
                      "radial-gradient(circle, rgba(0, 0, 0, 1) -20%, rgba(2, 67, 41, 1) 100%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Towing List
                  </MDTypography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearch}
                      />
                    </Search>
                  </Box>
                </MDBox>
                <Box sx={{ padding: "24px" }}>
                  {loading ? (
                    <MDBox
                      py={1.5}
                      px={3}
                      sx={{
                        textAlign: "center",
                      }}
                      colspan="4"
                    >
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "grey",
                          marginTop: "12px",
                        }}
                      />
                    </MDBox>
                  ) : towingList && towingList.length > 0 ? (
                    towingList.map((value, idx) => (
                      <Box
                        sx={{
                          border: "1px solid rgba(204, 206, 205, 1)",
                          borderRadius: 2,
                          p: 1.5,
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between",
                          mb: 2,
                          flexWrap: "wrap",
                          gap: "12px",
                        }}
                        key={idx}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "500px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#344767",
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              textTransform: "capitalize",
                              marginRight: "8px",
                            }}
                          >
                            {value?.company_name || ""}
                          </Typography>

                          <Typography
                            sx={{
                              color: "#344767",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              display: "flex", // To align the icon with the text
                              alignItems: "start",
                            }}
                          >
                            <LocationOnIcon
                              sx={{
                                marginRight: 1,
                                fill: "rgba(4, 167, 102, 1)",
                                marginTop: 0.5,
                              }}
                            />{" "}
                            {/* Location icon */}
                            {value?.location || ""}
                          </Typography>

                          <Typography
                            sx={{
                              color: "#344767",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              display: "flex", // To align the icon with the text
                              alignItems: "center",
                            }}
                          >
                            <PhoneIcon
                              sx={{
                                marginRight: 1,
                                fill: "rgba(4, 167, 102, 1)",
                              }}
                            />{" "}
                            {/* Phone icon */}
                            {value?.dial_code || ""} {value?.phone_no || ""}
                          </Typography>

                          <Typography
                            sx={{
                              color: "#344767",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              display: "flex", // To align the icon with the text
                              alignItems: "center",
                            }}
                          >
                            {/* <AttachMoneyIcon
                              sx={{
                                marginRight: 1,
                                fill: "rgba(4, 167, 102, 1)",
                              }}
                            /> */}
                            {value?.currency_symbol.match(/\((.*?)\)/)?.[1] ||
                              ""}
                            {/* Money icon */}
                            {/* {`${value?.currency_symbol} ${value?.towing_charges}` || ""} */}
                            {value?.towing_charges || ""}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#344767",
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              textTransform: "capitalize",
                              marginRight: "8px",
                            }}
                          >
                            Range :
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "0.875rem",
                                fontWeight: "400",
                                textTransform: "capitalize",
                              }}
                            >
                              {value?.range || ""}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            onClick={() => {
                              setOpen(true);
                              setEditCompany(value);
                            }}
                            sx={{
                              background: "rgba(250, 190, 25, 0.1) !important",
                              padding: "5px 10px",
                              color: "rgba(250, 190, 25, 1)!important",
                              fontSize: 14,
                              fontWeight: 500,
                              textTransform: "capitalize",
                              marginRight: "10px !important",
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              setDeleteOpen(true);
                              setCompanyId(value?.id);
                            }}
                            sx={{
                              background: "rgba(249, 52, 52, 0.1) !important",
                              padding: "5px 10px",
                              color: "rgba(249, 52, 52, 1)!important",
                              fontSize: 14,
                              fontWeight: 500,
                              textTransform: "capitalize",
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        borderRadius: 2,
                        p: 1.5,
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#344767",
                          fontSize: "1rem",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          marginRight: "8px",
                        }}
                      >
                        No user found
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  alignItems: "end",
                }}
              >
                <Pagination
                  count={totalPage} // Total pages based on user data
                  page={currentPage} // Current page
                  onChange={handlePageChange} // Handle page change
                  color="grey" // You can change the color if needed
                  // variant="outlined"
                  shape="rounded"
                  sx={{
                    "& .css-1eaa915-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                      {},
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Towing;
