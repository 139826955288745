import React from "react";

const RepairHistory = (props) => {
    return (
        <>
            Repair History
        </>
    );
};

export default RepairHistory;